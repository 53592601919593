.author {
  .purchasebox{
    @include minquery(769) {
      display: none;
    }
  }
  .blockauthor {
    @include flexbox(flex-start, flex-start);
    flex-wrap: nowrap;
    margin-top: 40px;
    @include query($q768) {
      flex-wrap: wrap;
      position: relative;
      margin-top: 0;
      padding-top: 50px;
    }
    &--img {
      width: 160px;
      height: 160px;
      border-radius: 99px;
      margin-right: 30px;
      overflow: hidden;
      @include query($q768) {
        max-width: 150px;
        height: 150px;
        flex: 0 0 100%;
        margin: 0 auto;
      }
    }
    &--info {
      width: calc(100% - 190px);
      @include query($q768) {
        flex: 0 0 100%;
        width: 100%;
      }
      .blocksocial{
        margin-top: 0;
        margin-bottom: 30px;
        @include query($q768) {
          &.socialshare{
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        &.socialauthor{
          margin-top: 40px;
          margin-bottom: 0;
          @include query($q768) {
            margin-top: 15px;
          }
        }
      }
      .description{
        text-align: justify;
        font-size: 16px;
      }
    }
  }//blockauthor

  .blockrecomend{
    margin-bottom: 0;
    @include query($q768) {
      margin-top: 40px;
    }
    .blocktitle{
      color: $blue;
      background: $bgbody;
      border-bottom: 0;
      margin: 0;
      padding: 16px 20px;
    }
    &__list{
      &--item{
        margin: 30px 0;
        @include query($q768) {
          margin: 15px 0;
        }
      }
      &--img{
        max-width: 200px;
        width: 200px;
        @include query($q768) {
          min-width: 105px;
          max-width: 105px;
        }
      }
      &--text{
        width: calc(100% - 225px);
        @include query($q768) {
          flex: 1 1 auto;
          width: 100%;
        }
        .title{
          text-align: justify;
          font-size: 18px;
          @include query($q768) {
            font-size: 15px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            overflow: hidden;
            max-height: 4.5em;
            font-weight: bold;
          }
        }
        .description{
          text-align: justify;
          font-size: 16px;
          margin-top: 10px;
          @include query($q768) {
            display: none;
          }
        }
      }
    }
  }//recomendlist

  .textellipsis {
    br {
      display: none !important;
    }
  }
}