.pagination{
  margin: 50px 0 30px; 
  @include query($q768){
    margin: 20px 0 10px;
  }
  ul{
    @include flexbox(center, center);
    li{
      font-size: 12px;
      margin: 0 2px;
      a{
        font-size: 12px;
        background: #fff; 
        border:1px solid #e9e9e9; 
        display: block;
        text-align: center;
        width: 60px;
        height: 60px;
        @include flexbox(center, center);
        color: $bgblue;
        &:hover{
          background: $bgblue;
          color: #fff;
        }
      }
      span.current{
        width: 60px;
        height: 60px;
        @include flexbox(center, center);
        background: $bgblue;
        color: #fff;
      }
    }
  }
}