body {
    &.chrome,
    &.firefox,
    &.edge {}
    &.secondpage {}
}
.chrome {}
.firefox {}
.edge {}
.safari {}
.ios {}
//FIX IE
.fixie {
    body {
        overflow: visible;
    }
    .custom-object-fit, .custom-object-contain {
        position: relative;
        background-position: center center;
        background-repeat: no-repeat;
        img {
            opacity: 0;
        }
    }
    .custom-object-fit {
        background-size: cover;
    }
    .custom-object-contain {
        background-size: contain;
    }
    .GloNav__inside--list li{
        flex: 0 0 125px;
    }
}
//END FIX IE
//END FIXLAYOUT