.sidebar{
  @include query($q768){
    background: #fff;
    .widget_menu{
      margin-top: 0;
    }
  }
}
.widget{
  @include query($q768){
    background: #fff;
    margin-top: 50px;
  }
  @include minquery(768){
    margin-bottom: 12px;
  }
  &-title{
    @include flexbox(center, center);
    @include minquery(769){
      background: $bgblue;
      min-height: 60px;
    }
    @include query($q768){
      margin-bottom: 15px;
    }
    &--tab{
      font-weight: bold;
      font-size: 20px;
      text-align: center;
      color: #fff;
      line-height: 1.2;
      font-weight: 500;
      padding: 16px 0;
      width: 100%;
      @include query($q768){
        font-size: 19px;
        position: relative;
        color: $bgblue;
        font-weight: 900;
        padding: 0;
        padding-bottom: 15px;
        &::after{
          position: absolute;
          content: '';
          background: $bgblue;
          width: 34px;
          height: 5px;
          bottom: 0;
          left: 50%;
          margin-left: -17px;
        }
      }
    }
  }
  &_banner{
    @include query($q768){
      display: none;
    }
  }
  &_nav_menu{
    background: #fff;
    padding: 5px 0;
    @include minquery(769){
      padding: 5px 15px;
      border: 1px solid #d1eaf7;
    }
    &--ul{
      margin-left: 0 !important;
      li{
        list-style: none !important;
      }
      @include query($q768){
        border-top: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        > li a{
          padding: 10px 15px;
        }
      }
    }
    a{
      display: block;
      font-size: 15px;
      padding: 10px 0;
      &.acr_title{
        padding-right: 15px;
        @include query($q768){
          padding: 14px 40px 14px 15px;
        }
      }
      @include minquery(769){
        font-size: 14px;
        &:hover{
          text-decoration: underline;
        }
      }
      @include query(320){
        font-size: 14px;
      }
    }
    .menu-item{
      border-bottom: 1px dotted #aaa;
      @include query($q768){
        border-bottom: 1px solid #d6d6d6;
      }
      &:last-child{
        border-bottom: 0;
      }
      &.current-menu-parent, &.current-menu-ancestor{
        @include query($q768){
          > a{
            font-weight: bold;
            color: #4072d7;
          }
        }
        .current_page_item{
          > a{
            text-decoration: none;
            background: $bgbody;
            @include query($q768){
              &::after{
                content: '' !important;
              }
            }
            span{
              background: linear-gradient(0deg, #FFECB9 50%, transparent 50%);
            }
          }
        }
        .sub-menu.acr_con{
          // .current-page-parent, .current-page-ancestor{
          //   background: $bgbody;
          // }
          // .current-menu-item{
          //   background: $bgbody;
          // }
        }
        // ul ul li.current_page_item{
        //   a::before{
        //     @include minquery(769){
        //       display: inline !important;
        //       background: none;
        //     }
        //     @include query($q768){
        //       left: 15px;
        //       width: calc(100% - 15px);
        //     }
        //   }
        // }
      }
      &-has-children{
        > a{
          position: relative;
          &::after{
            position: absolute;
            content: '';
            background: url(../img/common/icon/icon-plus.png) no-repeat center center;
            background-size: 12px;
            width: 12px;
            height: 13px;
            right: 0;
            top: 14px;
            @include query($q768){
              right: 15px;
              top: 18px;
            }
          }
          &.open::after{
            background: url(../img/common/icon/icon-minus.png) no-repeat center center;
            background-size: 12px;
          }
        }
      }
      .sub-menu{
        &.acr_con{
          border-top: 1px dotted #aaa;
          display: none;
          @include query($q768){
            border-top: 1px solid #d6d6d6;
          }
        }
        li{
          a{
            padding: 15px;
            position: relative;
            &::before, &::after{
              background: none;
            }
          }
          &.menu-item-has-children{
            a{
              @include query($q768){
                padding-left: 30px;
              }
            }
            .sub-menu{
              border-top: 1px dotted #aaa;
              @include query($q768){
                border-top: 1px solid #d6d6d6;
              }
              @include minquery(769){
                li:first-child{
                  border-bottom: 1px dotted #aaa;
                }
                li:last-child{
                  border-bottom: 0;
                }
              }
              a{
                @include arrblue;
                &::after{
                  right: 0;
                }
                @include query($q768){
                  padding-left: 30px;
                  padding-right: 40px;
                  text-align: justify;
                  &::after{
                    font-size: 14px;
                    right: 15px;
                    margin-top: -7px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &_popular{
    background: #fff;
    &__cont{
      padding: 5px 0;
      @include minquery(769){
        padding: 5px 15px;
        border: 1px solid #d1eaf7;
      }
      @include query($q768){
        display: flex;
        flex-wrap: wrap;
      }
    }
    &--item{
      border-bottom: 1px dotted #999;
      @include query($q768){
        border-bottom: 1px solid #d6d6d6;
      }
      &:first-child{
        @include query($q768){
          border-top: 1px solid #d6d6d6;
        }
      }
      &:last-child{
        border-bottom: none;
        @include query($q768){
          border-bottom: 1px solid #d6d6d6;
        }
      }
      a{
        @include flexbox(flex-start, flex-start);
        flex-wrap: nowrap;
        padding: 10px 0;
        @include hover;
        @include query($q768){
          padding: 15px;
        }
      }
      @include query($q768){
        flex: 0 0 100%;
      }
      @include minquery(769){
        width: 100%;
      }
    }
    &--img{
      min-width: 88px;
      flex: 0 0 88px;
      margin-right: 15px;
      position: relative;
      span{
        position: absolute;
        color: #fff;
        text-align: center;
        display: block;
        bottom: 1px;
        z-index: 9;
        font-size: 8px;
        line-height: 1.2;
        width: 100%;
        @include query($q768){
          font-size: 11px;
          bottom: 2px;
        }
      }
      @include minquery(769){
        min-width: 65px;
        width: 65px;
        height: 63px;
        flex: 0 0 65px;
        max-width: 65px;
      }
    }
    &--txt{
      overflow: hidden;
      padding: 0 12px 0 0;
      @include arrblue;
      &::after{
        right: 0;
      }
      @include query($q768){
        width: 100%;
        min-height: 88px;
        padding-right: 24px;
      }
      .tit{
        font-size: 16px;
        text-align: justify;
        height: 3em;
        color: #434343;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 100%;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        @include query($q768){
          font-weight: bold;
        }
        @include minquery(769){
          font-size: 13px;
        }
      }
      .cate{
        font-size: 11px;
        color: $orange;
        border: 1px solid $orange;
        display: inline-block;
        padding: 2px 8px;
        @include query($q768){
          font-size: 12px;
          padding: 3px 15px;
          margin-bottom: 7px;
        }
      }
    }
  }
  &_ranking{
    overflow-x: hidden;
    @include minquery(769){
      .widget-title.dissp{
        display: none !important;
      }
    }
    &--tablist{
      margin-top: 20px;
      .slick-list{
        border-bottom: 12px solid $blue;
      }
      @include query($q768){
        width: calc(100% + 3px);
      }
      @include minquery(769){
        display: none !important;
      }
    }
    &--tabitem{
      background: #7acae9;
      margin-right: 3px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      cursor: pointer;
      span{
        @include flexbox(center, center);
        width: 100%;
        text-align: center;
        font-weight: bold;
        height: 50px;
        color: #fff;
      }
      &.slick-current{
        background: $blue;
      }
    }
    &--tab{
      background: #fff;
      @include minquery(769){
        margin-bottom: 14px;
      }
      @include query($q768){
        .widget-title{
          margin: 0;
        }
        .widget-title--tab{
          display: none;
        }
      }
    }
    &__cont{
      @include minquery(769){
        border: 1px solid #d1eaf7;
        padding: 5px 15px;
      }
    }
    &--item{
      position: relative;
      border-bottom: 1px dotted #999;
      @include query($q768){
        border-bottom: 1px solid #ccc;
        @include arrblue;
        &::after{
          color: #ccc;
          right: 12px;
        }
      }
      &:last-child{
        border-bottom: none;
      }
      @for $i from 1 to 6 {
        $bgcl1: #d4ba60;
        $bgcl2: #c0c0c0;
        $bgcl3: #bf8e8e;
        $bgcl4: #87bbca;
        $bgcl5: #87bbca;
        &:nth-child(#{$i}){
          &::before{
            content: '';
            position: absolute;
            background: url(../img/common/icon/icon-no#{$i}.png) no-repeat;
            background-size: 30px;
            width: 30px;
            height: 37px;
            top: 15px;
            left: 0;
            @include query($q768){
              height: 100%;
              top: 0;
            }
          }
        }
        @include query($q768){
          &:nth-child(1)::before{
            background: $bgcl1 url(../img/common/icon/icon-txtno1.png) no-repeat center center;
            background-size: 15px;
          }
          &:nth-child(2)::before{
            background: $bgcl2 url(../img/common/icon/icon-txtno2.png) no-repeat center center;
            background-size: 15px;
          }
          &:nth-child(3)::before{
            background: $bgcl3 url(../img/common/icon/icon-txtno3.png) no-repeat center center;
            background-size: 15px;
          }
          &:nth-child(4)::before{
            background: $bgcl4 url(../img/common/icon/icon-txtno4.png) no-repeat center center;
            background-size: 15px;
          }
          &:nth-child(5)::before{
            background: $bgcl5 url(../img/common/icon/icon-txtno5.png) no-repeat center center;
            background-size: 15px;
          }
        }
      }
      a{
        @include flexbox(flex-start, center);
        flex-wrap: nowrap;
        padding: 10px 0;
        @include query($q768){
          padding: 12px 30px 12px 40px; 
        }
        &:hover{
          opacity: 0.7;
        }
      }
    }
    &--img{
      min-width: 100px;
      flex: 0 0 100px;
      max-width: 100px;
    }
    &--txt{
      text-align: left;
      padding: 0 0 0 50px;
      @include query($q768){
        padding-left: 15px;
      }
      .name{
        display: block;
        width: 100%;
        color: #4072d7;
        font-size: 16px;
        text-decoration: underline;
        @include query($q768){
          font-weight: bold;
          text-decoration: none;
          font-size: 15px;
          color: #000;
        }
      }
      .description{
        font-size: 12px;
        text-align: justify;
        height: 3em;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 100%;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        @include minquery(769){
          display: none;
        }
      }
    }
    .linklist{
      margin: 5px 0 15px;
      text-align: right;
      @include query($q768){
        display: none;
      }
      a.alink{
        word-break: break-all;
        word-wrap: break-word;
        padding-right: 10px;
        padding-left: 15px;
        color: $link;
        text-decoration: underline;
        font-size: 14px;
        position: relative;
        &::before{
          content: "";
          display: block;
          width: 8px;
          height: 14px;
          background: url(../img/common/icon/arr-blue.png) no-repeat;
          background-size: 8px;
          position: absolute;
          top: 3px;
          bottom: 0;
          left: 0;
          margin: auto 0;
        }
        &:hover{
          text-decoration: none;
        }
      }
    }
    &--star{
      @include flexbox(flex-start, center);
      margin: 8px 0 0;
      @include query($q768){
        position: relative;
        margin: 4px 0;
      }
      .rating-box{
        line-height: 1;
        position: relative;
        white-space: nowrap;
        display: inline-block;
        span{
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          z-index: 1;
          overflow: hidden;
          line-height: 1;
        }
        .fa-star{
          font-family: fontAwesome;
          font-weight: 400;
          font-style: normal;
          display: inline-block;
          margin: .1rem;
          margin-left: 0;
          color: #C1C1C1;
          text-rendering: auto;
          font-size: 12px;
          &:first-child {
            margin-left: 0;
          }
        }
        span .fa-star {
          color: #fbd261 !important;
          position: relative;
          &::after{
            position: absolute;
            content: '';
            background: url(../img/common/icon/icon-start.png) no-repeat;
            background-size: 11px;
            left: 0;
            top: 0;
            width: 11px;
            height: 11px;
            z-index: 9;
            @include query($q768){
              background-size: 9px;
            }
          }
        }
      }
      .rating-num{
        font-weight: bold;
        margin-left: 10px;
        display: inline-block;
        color: $red;
        font-size: 14px;
        @include query($q768){
          font-size: 12px;
          line-height: 1;
        }
      }
    }
    &--btn{
      a{
        font-size: 12px;
        max-width: 220px;
      }
    }
  }
  &_pickup{
    margin-top: 20px;
    @include query($q768){
      margin-top: 50px;
    }
    .widget-title{
      @include minquery(769){
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 20px;
        background: none;
        border-left: 8px solid $bgblue;
        min-height: 32px;
        h3{
          font-weight: bold;
          font-size: 20px;
          color: $bgblue;
          text-align: left;
          line-height: 28px;
          height: 32px;
          padding: 0;
          padding-left: 18px;
        }
      }
    }
    p{
      margin: 0 auto;
      margin-bottom: 14px;
      text-align: center;
      img{
        margin: 0 auto;
      }
      @include minquery(768){
        max-width: 250px;
      }
    }
  }
}