
.breadcrumb {
  display: block;
  max-width: 1080px;
  margin: 0 auto;
  height: 30px;
  @include query($q768) {
    padding: 5px 15px;
    background: $bgbody;
  }
  @include minquery(769) {
    height: 45px;
    padding-top: 12px;
  }
  ol {
    @include flexbox(flex-start, center);
    @include minquery(769) {
      flex-wrap: wrap;
    }
    @include query($q768) {
      overflow-x: scroll;
    }
	}
  li {
		display: inline-block;
    font-weight: 300;
    padding: 0;
    font-size: 12px;
    margin-left: 5px;
    color: $clgray;
		&:before {
			content: "\f105";
			font-family: fontAwesome;
			margin-right: 3px;
		}
		&:first-child {
			&:before {
				display: none;
			}
			padding-left: 0;
			margin-left: 0;
		}
    @include minquery(769) {
      font-size: 14px;
    }
    @include query($q768) {
      word-break: keep-all;
    }
    a {
      color: $link;
      font-size: 12px;
      text-decoration: underline;
      @include minquery(769) {
				font-size: 14px;
				&:hover {
					text-decoration: underline;
					opacity: 0.8;
				}
      }
    }
  }
}