@charset "UTF-8";
* { margin: 0; padding: 0; box-sizing: border-box; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; }

html { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; -ms-box-sizing: border-box; -o-transition: border-box; box-sizing: border-box; overflow-x: hidden; }

body { -webkit-text-size-adjust: 100%; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; outline: 0; font-size: 100%; font-weight: normal; vertical-align: baseline; background: 0 0; }

h1 { font-size: 2em; margin: 0.67em 0; }

hr { box-sizing: content-box; /* 1 */ overflow: visible; /* 2 */ display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; }

pre { font-family: monospace, monospace; /* 1 */ font-size: 1em; /* 2 */ }

abbr[title] { border-bottom: none; /* 1 */ text-decoration: underline; /* 2 */ text-decoration: underline dotted; /* 2 */ }

b, strong { font-weight: bold !important; }

code, kbd, pre, samp, tt { font-family: monospace, monospace; /* 1 */ font-size: 1em; /* 2 */ }

small { font-size: 80%; }

sub, sup { font-size: 75%; position: relative; vertical-align: baseline; border: 0; outline: 0; }

sub { bottom: -0.25em; }

sup { top: -0.5em; }

button, input, optgroup, select, textarea { font-family: inherit; /* 1 */ font-size: 100%; /* 1 */ line-height: 1.15; /* 1 */ }

button, input { /* 1 */ overflow: visible; -webkit-transition: all .2s ease-in-out 0s; -moz-transition: all .2s ease-in-out 0s; -ms-transition: all .2s ease-in-out 0s; -o-transition: all .2s ease-in-out 0s; transition: all .2s ease-in-out 0s; }

button, select { /* 1 */ text-transform: none; }

input, select { vertical-align: middle; }

input:focus { outline: 0; }

textarea { overflow: auto; }

fieldset { padding: 0.35em 0.75em 0.625em; }

fieldset, label { margin: 0; padding: 0; }

table { border-spacing: 0; border-collapse: collapse; table-layout: fixed; }

progress { vertical-align: baseline; }

button, [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { border-style: none; padding: 0; }

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring { outline: 1px dotted ButtonText; }

[type="checkbox"], [type="radio"] { box-sizing: border-box; /* 1 */ padding: 0; /* 2 */ }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button { height: auto; }

[type="search"] { -webkit-appearance: textfield; /* 1 */ outline-offset: -2px; /* 2 */ }

[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

::-webkit-file-upload-button { -webkit-appearance: button; /* 1 */ font: inherit; /* 2 */ }

summary { display: list-item; }

template, [hidden], .hidden { display: none; }

blockquote, q { quotes: none; }

blockquote:after, blockquote:before, q:after, q:before { content: ""; content: none; }

article, aside, details, figcaption, figure, footer, header, main, menu, nav, section { display: block; }

img { vertical-align: top; border-style: none; line-height: 0; font-size: 0; -ms-interpolation-mode: bicubic; background: 0 0; zoom: 1; }

img, iframe { max-width: 100%; line-height: 0; }

ol, ul { list-style: none; }

a { background-color: transparent; font-size: 100%; vertical-align: baseline; text-decoration: none; background: 0 0; line-height: normal; }

a:focus { outline: 0; }

a img { opacity: 1; transition: ease-out 0.3s; }

a img:hover { opacity: 0.7; transition: ease-out 0.3s; }

ins { background-color: #ff9; color: #000; text-decoration: none; }

mark { background-color: #ff9; color: #000; font-style: italic; font-weight: 700; }

del { text-decoration: line-through; }

abbr[title], dfn[title] { border-bottom: 1px dotted; cursor: help; }

a, button, input { -webkit-transition: all .3s ease-in-out 0s; -moz-transition: all .3s ease-in-out 0s; -o-transition: all .3s ease-in-out 0s; -ms-transition: all .3s ease-in-out 0s; transition: all .3s ease-in-out 0s; }

b, strong { font-weight: bold; }

b span, strong span { font-weight: bold; }

/*====================================================================================
■01.MARGIN DEFAULT
====================================================================================*/
.mt00 { margin-top: 0 !important; }

/*====================================================================================
■WIDTH DEFAULT
====================================================================================*/
/*====================================================================================
■MARGIN% DEFAULT
====================================================================================*/
.pull_left { float: left; }

.pull_right { float: right; }

.text_left { text-align: left !important; }

.text_right { text-align: right !important; }

.text_center { text-align: center !important; }

.text_justify { text-align: justify !important; }

.text_nowrap { white-space: nowrap !important; }

.text_lower { text-transform: lowercase !important; }

.text_upper { text-transform: uppercase !important; }

.text_capitalize { text-transform: capitalize !important; }

.text_normal { font-weight: normal !important; }

.text_bold { font-weight: bold !important; }

.text_line { text-decoration: underline; }

.text_noline { text-decoration: none !important; }

.text_over { overflow: hidden !important; }

.lh00 { line-height: 0 !important; }

.clearfix:before, .clearfix:after { content: "\0020"; display: block; height: 0; overflow: hidden; }

.clearfix:after { clear: both; }

.clearfix { zoom: 1; }

.visible { visibility: visible; }

.visible-hidden { visibility: hidden; }

.dispc, .breakpc { display: block; }

@media all and (max-width: 768px) { .dispc, .breakpc { display: none; } }

.dissp, .breaksp { display: block; }

@media all and (min-width: 769px) { .dissp, .breaksp { display: none; } }

.flexFont { font-size: 100%; }

.obj__fit, .imgfit { width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; font-family: 'object-fit: cover;'; }

.overflow { overflow: hidden; }

@font-face { font-family: 'Noto Sans JP'; font-style: normal; font-weight: 100; src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format("opentype"); }

@font-face { font-family: 'Noto Sans JP'; font-style: normal; font-weight: 300; src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format("opentype"); }

@font-face { font-family: 'Noto Sans JP'; font-style: normal; font-weight: 400; src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format("opentype"); }

@font-face { font-family: 'Noto Sans JP'; font-style: normal; font-weight: 500; src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format("opentype"); }

@font-face { font-family: 'Noto Sans JP'; font-style: normal; font-weight: 700; src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format("opentype"); }

@font-face { font-family: 'Noto Sans JP'; font-style: normal; font-weight: 900; src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format("opentype"); }

@-webkit-keyframes fadeInLeft { 0% { opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% { opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes fadeInLeft { 0% { opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% { opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes fadeInLeft { 0% { opacity: 0; -webkit-transform: translateX(-20px); -moz-transform: translateX(-20px); -ms-transform: translateX(-20px); -o-transform: translateX(-20px); transform: translateX(-20px); }
  100% { opacity: 1; -webkit-transform: translateX(0); -moz-transform: translateX(0); -ms-transform: translateX(0); -o-transform: translateX(0); transform: translateX(0); } }

@-o-keyframes fadeInLeft { 0% { opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% { opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeft { 0% { opacity: 0;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% { opacity: 1;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInUp { 0% { opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% { opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes fadeInUp { 0% { opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% { opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes fadeInUp { 0% { opacity: 0; -webkit-transform: translateY(20px); -moz-transform: translateY(20px); -ms-transform: translateY(20px); -o-transform: translateY(20px); transform: translateY(20px); }
  100% { opacity: 1; -webkit-transform: translateY(0); -moz-transform: translateY(0); -ms-transform: translateY(0); -o-transform: translateY(0); transform: translateY(0); } }

@-o-keyframes fadeInUp { 0% { opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% { opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUp { 0% { opacity: 0;
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px); }
  100% { opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0); } }

.fixie body { overflow: visible; }

.fixie .custom-object-fit, .fixie .custom-object-contain { position: relative; background-position: center center; background-repeat: no-repeat; }

.fixie .custom-object-fit img, .fixie .custom-object-contain img { opacity: 0; }

.fixie .custom-object-fit { background-size: cover; }

.fixie .custom-object-contain { background-size: contain; }

.fixie .GloNav__inside--list li { flex: 0 0 125px; }

.Header { background: #fff; }

.Header__cont { position: relative; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: space-between; align-items: center; height: 95px; }

@media all and (max-width: 768px) { .Header__cont { height: 60px; } }

@media all and (max-width: 520px) { .Header__cont { height: 45px; } }

.Header__cont--logo { position: relative; z-index: 1; }

.Header__cont--logo img { height: 56px; }

@media all and (max-width: 768px) { .Header__cont--logo { margin: 0 auto; right: -30px; }
  .Header__cont--logo img { height: 26px; } }

@media all and (max-width: 520px) { .Header__cont--logo { right: -22px; } }

@media all and (min-width: 769px) { .Header__cont--nav { flex: 0 0 70%; } }

.hamburger { cursor: pointer; position: relative; top: 0; z-index: 1000; height: 60px; width: 60px; transition: all .3s; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; }

@media all and (min-width: 769px) { .hamburger { display: none !important; } }

@media all and (max-width: 520px) { .hamburger { width: 44px; height: 44px; background: none; } }

.hamburger__inside { z-index: 99; position: relative; height: 38%; width: 56%; }

.hamburger__inside span { height: 2px; position: absolute; background: #000; text-shadow: 0 0 0 #000; display: inline-block; width: 100%; -webkit-transition: all 0.4s ease-in-out 0s; -moz-transition: all 0.4s ease-in-out 0s; -ms-transition: all 0.4s ease-in-out 0s; -o-transition: all 0.4s ease-in-out 0s; transition: all 0.4s ease-in-out 0s; }

.hamburger__inside span:first-child { top: 0; }

.hamburger__inside span:nth-child(2) { top: 0; bottom: 0; margin: auto; position: absolute; }

.hamburger__inside span:last-child { bottom: 0; }

@media all and (max-width: 520px) { .hamburger__inside span { background: #333; } }

.GloNav { position: relative; z-index: 999; width: 100%; }

@media all and (max-width: 768px) { .GloNav { position: absolute; left: 0; top: 61px; } }

@media all and (max-width: 520px) { .GloNav { top: 45px; } }

.GloNav__inside { width: 100%; z-index: 99; }

@media all and (max-width: 768px) { .GloNav__inside { position: absolute; left: -100%; background: rgba(255, 255, 255, 0.95); transition: all .3s ease; opacity: 0; top: 2px; } }

.GloNav__inside--list { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: space-between; align-items: center; }

@media all and (max-width: 768px) { .GloNav__inside--list { display: block; height: auto; flex-wrap: wrap; } }

.GloNav__inside--list li { position: relative; list-style: none; }

.GloNav__inside--list li a { font-size: 15px; color: #333; text-decoration: none; padding: 15px 0; text-align: center; }

@media all and (min-width: 769px) { .GloNav__inside--list li a:hover { opacity: 0.7; }
  .GloNav__inside--list li a:hover img { opacity: 1; } }

.GloNav__inside--list li a span { font-weight: bold; }

@media all and (min-width: 769px) { .GloNav__inside--list li a { font-size: 13px; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; flex-wrap: wrap; padding: 0; }
  .GloNav__inside--list li a img { width: 30px; height: 30px; }
  .GloNav__inside--list li a span { display: block; flex: 0 0 100%; font-size: 14px; margin-top: 7px; } }

@media all and (max-width: 768px) { .GloNav__inside--list li a { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: center; padding: 20px 15px; text-align: left; }
  .GloNav__inside--list li a img { margin-right: 15px; width: 25px; height: 25px; } }

@media all and (min-width: 769px) { .GloNav__inside--list li { flex: 0 0 120px; }
  .GloNav__inside--list li:last-child { flex: 0 0 50px; }
  .GloNav__inside--list li:hover a { text-decoration: underline; } }

@media all and (max-width: 768px) { .GloNav__inside--list li { border-bottom: 1px solid #ccc; flex: 0 0 100%; } }

.GloNav--open .GloNav__inside { left: 0; opacity: 1; }

.GloNav--open .GloNav__inside--list > li { -webkit-animation-name: fadeInUp; -moz-animation-name: fadeInUp; -ms-animation-name: fadeInUp; -o-animation-name: fadeInUp; animation-name: fadeInUp; -webkit-animation-iteration-count: 1; -moz-animation-iteration-count: 1; -ms-animation-iteration-count: 1; -o-animation-iteration-count: 1; animation-iteration-count: 1; -webkit-animation-duration: 0.3s; -moz-animation-duration: 0.3s; -ms-animation-duration: 0.3s; -o-animation-duration: 0.3s; animation-duration: 0.3s; -webkit-animation-delay: 0s; -moz-animation-delay: 0s; -ms-animation-delay: 0s; -o-animation-delay: 0s; animation-delay: 0s; -webkit-animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1); -moz-animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1); -ms-animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1); -o-animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1); animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1); -webkit-animation-fill-mode: both; -moz-animation-fill-mode: both; -ms-animation-fill-mode: both; -o-animation-fill-mode: both; animation-fill-mode: both; -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; -ms-backface-visibility: hidden; -o-backface-visibility: hidden; backface-visibility: hidden; }

@media all and (max-width: 768px) { .GloNav--open .GloNav__inside--list > li { -webkit-animation-name: fadeInLeft; -moz-animation-name: fadeInLeft; -ms-animation-name: fadeInLeft; -o-animation-name: fadeInLeft; animation-name: fadeInLeft; -webkit-animation-iteration-count: 1; -moz-animation-iteration-count: 1; -ms-animation-iteration-count: 1; -o-animation-iteration-count: 1; animation-iteration-count: 1; -webkit-animation-duration: 0.6s; -moz-animation-duration: 0.6s; -ms-animation-duration: 0.6s; -o-animation-duration: 0.6s; animation-duration: 0.6s; -webkit-animation-delay: 0s; -moz-animation-delay: 0s; -ms-animation-delay: 0s; -o-animation-delay: 0s; animation-delay: 0s; -webkit-animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1); -moz-animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1); -ms-animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1); -o-animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1); animation-timing-function: cubic-bezier(0, 0.2, 0.8, 1); -webkit-animation-fill-mode: both; -moz-animation-fill-mode: both; -ms-animation-fill-mode: both; -o-animation-fill-mode: both; animation-fill-mode: both; -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; -ms-backface-visibility: hidden; -o-backface-visibility: hidden; backface-visibility: hidden; } }

.GloNav--open .GloNav__inside--list > li:nth-child(1) { animation-delay: 0.1s; }

.GloNav--open .GloNav__inside--list > li:nth-child(2) { animation-delay: 0.2s; }

.GloNav--open .GloNav__inside--list > li:nth-child(3) { animation-delay: 0.3s; }

.GloNav--open .GloNav__inside--list > li:nth-child(4) { animation-delay: 0.4s; }

.GloNav--open .GloNav__inside--list > li:nth-child(5) { animation-delay: 0.5s; }

.GloNav--open .GloNav__inside--list > li:nth-child(6) { animation-delay: 0.6s; }

.GloNav--open .GloNav__inside--list > li:nth-child(7) { animation-delay: 0.7s; }

.GloNav--open .hamburger__inside span { text-shadow: none; height: 2px; }

.GloNav--open .hamburger__inside span:nth-child(1) { transform: translateY(10px) rotate(-225deg); }

@media all and (max-width: 520px) { .GloNav--open .hamburger__inside span:nth-child(1) { transform: translateY(7px) rotate(-225deg); } }

.GloNav--open .hamburger__inside span:nth-child(2) { transform: translateY(0) rotate(225deg); width: 100%; }

.GloNav--open .hamburger__inside span:nth-child(3) { opacity: 0; }

.breadcrumb { display: block; max-width: 1080px; margin: 0 auto; height: 30px; }

@media all and (max-width: 768px) { .breadcrumb { padding: 5px 15px; background: #ebf8ff; } }

@media all and (min-width: 769px) { .breadcrumb { height: 45px; padding-top: 12px; } }

.breadcrumb ol { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: center; }

@media all and (min-width: 769px) { .breadcrumb ol { flex-wrap: wrap; } }

@media all and (max-width: 768px) { .breadcrumb ol { overflow-x: scroll; } }

.breadcrumb li { display: inline-block; font-weight: 300; padding: 0; font-size: 12px; margin-left: 5px; color: #7C7C7C; }

.breadcrumb li:before { content: "\f105"; font-family: fontAwesome; margin-right: 3px; }

.breadcrumb li:first-child { padding-left: 0; margin-left: 0; }

.breadcrumb li:first-child:before { display: none; }

@media all and (min-width: 769px) { .breadcrumb li { font-size: 14px; } }

@media all and (max-width: 768px) { .breadcrumb li { word-break: keep-all; } }

.breadcrumb li a { color: #4072d7; font-size: 12px; text-decoration: underline; }

@media all and (min-width: 769px) { .breadcrumb li a { font-size: 14px; }
  .breadcrumb li a:hover { text-decoration: underline; opacity: 0.8; } }

@media all and (max-width: 768px) { .sidebar { background: #fff; }
  .sidebar .widget_menu { margin-top: 0; } }

@media all and (max-width: 768px) { .widget { background: #fff; margin-top: 50px; } }

@media all and (min-width: 768px) { .widget { margin-bottom: 12px; } }

.widget-title { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; }

@media all and (min-width: 769px) { .widget-title { background: #02a3d4; min-height: 60px; } }

@media all and (max-width: 768px) { .widget-title { margin-bottom: 15px; } }

.widget-title--tab { font-weight: bold; font-size: 20px; text-align: center; color: #fff; line-height: 1.2; font-weight: 500; padding: 16px 0; width: 100%; }

@media all and (max-width: 768px) { .widget-title--tab { font-size: 19px; position: relative; color: #02a3d4; font-weight: 900; padding: 0; padding-bottom: 15px; }
  .widget-title--tab::after { position: absolute; content: ''; background: #02a3d4; width: 34px; height: 5px; bottom: 0; left: 50%; margin-left: -17px; } }

@media all and (max-width: 768px) { .widget_banner { display: none; } }

.widget_nav_menu { background: #fff; padding: 5px 0; }

@media all and (min-width: 769px) { .widget_nav_menu { padding: 5px 15px; border: 1px solid #d1eaf7; } }

.widget_nav_menu--ul { margin-left: 0 !important; }

.widget_nav_menu--ul li { list-style: none !important; }

@media all and (max-width: 768px) { .widget_nav_menu--ul { border-top: 1px solid #d6d6d6; border-bottom: 1px solid #d6d6d6; }
  .widget_nav_menu--ul > li a { padding: 10px 15px; } }

.widget_nav_menu a { display: block; font-size: 15px; padding: 10px 0; }

.widget_nav_menu a.acr_title { padding-right: 15px; }

@media all and (max-width: 768px) { .widget_nav_menu a.acr_title { padding: 14px 40px 14px 15px; } }

@media all and (min-width: 769px) { .widget_nav_menu a { font-size: 14px; }
  .widget_nav_menu a:hover { text-decoration: underline; } }

@media all and (max-width: 320px) { .widget_nav_menu a { font-size: 14px; } }

.widget_nav_menu .menu-item { border-bottom: 1px dotted #aaa; }

@media all and (max-width: 768px) { .widget_nav_menu .menu-item { border-bottom: 1px solid #d6d6d6; } }

.widget_nav_menu .menu-item:last-child { border-bottom: 0; }

@media all and (max-width: 768px) { .widget_nav_menu .menu-item.current-menu-parent > a, .widget_nav_menu .menu-item.current-menu-ancestor > a { font-weight: bold; color: #4072d7; } }

.widget_nav_menu .menu-item.current-menu-parent .current_page_item > a, .widget_nav_menu .menu-item.current-menu-ancestor .current_page_item > a { text-decoration: none; background: #ebf8ff; }

@media all and (max-width: 768px) { .widget_nav_menu .menu-item.current-menu-parent .current_page_item > a::after, .widget_nav_menu .menu-item.current-menu-ancestor .current_page_item > a::after { content: '' !important; } }

.widget_nav_menu .menu-item.current-menu-parent .current_page_item > a span, .widget_nav_menu .menu-item.current-menu-ancestor .current_page_item > a span { background: linear-gradient(0deg, #FFECB9 50%, transparent 50%); }

.widget_nav_menu .menu-item-has-children > a { position: relative; }

.widget_nav_menu .menu-item-has-children > a::after { position: absolute; content: ''; background: url(../img/common/icon/icon-plus.png) no-repeat center center; background-size: 12px; width: 12px; height: 13px; right: 0; top: 14px; }

@media all and (max-width: 768px) { .widget_nav_menu .menu-item-has-children > a::after { right: 15px; top: 18px; } }

.widget_nav_menu .menu-item-has-children > a.open::after { background: url(../img/common/icon/icon-minus.png) no-repeat center center; background-size: 12px; }

.widget_nav_menu .menu-item .sub-menu.acr_con { border-top: 1px dotted #aaa; display: none; }

@media all and (max-width: 768px) { .widget_nav_menu .menu-item .sub-menu.acr_con { border-top: 1px solid #d6d6d6; } }

.widget_nav_menu .menu-item .sub-menu li a { padding: 15px; position: relative; }

.widget_nav_menu .menu-item .sub-menu li a::before, .widget_nav_menu .menu-item .sub-menu li a::after { background: none; }

@media all and (max-width: 768px) { .widget_nav_menu .menu-item .sub-menu li.menu-item-has-children a { padding-left: 30px; } }

.widget_nav_menu .menu-item .sub-menu li.menu-item-has-children .sub-menu { border-top: 1px dotted #aaa; }

@media all and (max-width: 768px) { .widget_nav_menu .menu-item .sub-menu li.menu-item-has-children .sub-menu { border-top: 1px solid #d6d6d6; } }

@media all and (min-width: 769px) { .widget_nav_menu .menu-item .sub-menu li.menu-item-has-children .sub-menu li:first-child { border-bottom: 1px dotted #aaa; }
  .widget_nav_menu .menu-item .sub-menu li.menu-item-has-children .sub-menu li:last-child { border-bottom: 0; } }

.widget_nav_menu .menu-item .sub-menu li.menu-item-has-children .sub-menu a { position: relative; }

.widget_nav_menu .menu-item .sub-menu li.menu-item-has-children .sub-menu a::after { position: absolute; content: '\f054'; font-family: FontAwesome; font-size: 12px; right: 17px; top: 50%; margin-top: -6px; color: #7acae9; font-weight: normal; }

.widget_nav_menu .menu-item .sub-menu li.menu-item-has-children .sub-menu a::after { right: 0; }

@media all and (max-width: 768px) { .widget_nav_menu .menu-item .sub-menu li.menu-item-has-children .sub-menu a { padding-left: 30px; padding-right: 40px; text-align: justify; }
  .widget_nav_menu .menu-item .sub-menu li.menu-item-has-children .sub-menu a::after { font-size: 14px; right: 15px; margin-top: -7px; } }

.widget_popular { background: #fff; }

.widget_popular__cont { padding: 5px 0; }

@media all and (min-width: 769px) { .widget_popular__cont { padding: 5px 15px; border: 1px solid #d1eaf7; } }

@media all and (max-width: 768px) { .widget_popular__cont { display: flex; flex-wrap: wrap; } }

.widget_popular--item { border-bottom: 1px dotted #999; }

@media all and (max-width: 768px) { .widget_popular--item { border-bottom: 1px solid #d6d6d6; } }

@media all and (max-width: 768px) { .widget_popular--item:first-child { border-top: 1px solid #d6d6d6; } }

.widget_popular--item:last-child { border-bottom: none; }

@media all and (max-width: 768px) { .widget_popular--item:last-child { border-bottom: 1px solid #d6d6d6; } }

.widget_popular--item a { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: nowrap; padding: 10px 0; }

@media all and (min-width: 769px) { .widget_popular--item a:hover { opacity: 0.7; }
  .widget_popular--item a:hover img { opacity: 1; } }

@media all and (max-width: 768px) { .widget_popular--item a { padding: 15px; } }

@media all and (max-width: 768px) { .widget_popular--item { flex: 0 0 100%; } }

@media all and (min-width: 769px) { .widget_popular--item { width: 100%; } }

.widget_popular--img { min-width: 88px; flex: 0 0 88px; margin-right: 15px; position: relative; }

.widget_popular--img span { position: absolute; color: #fff; text-align: center; display: block; bottom: 1px; z-index: 9; font-size: 8px; line-height: 1.2; width: 100%; }

@media all and (max-width: 768px) { .widget_popular--img span { font-size: 11px; bottom: 2px; } }

@media all and (min-width: 769px) { .widget_popular--img { min-width: 65px; width: 65px; height: 63px; flex: 0 0 65px; max-width: 65px; } }

.widget_popular--txt { overflow: hidden; padding: 0 12px 0 0; position: relative; }

.widget_popular--txt::after { position: absolute; content: '\f054'; font-family: FontAwesome; font-size: 12px; right: 17px; top: 50%; margin-top: -6px; color: #7acae9; font-weight: normal; }

.widget_popular--txt::after { right: 0; }

@media all and (max-width: 768px) { .widget_popular--txt { width: 100%; min-height: 88px; padding-right: 24px; } }

.widget_popular--txt .tit { font-size: 16px; text-align: justify; height: 3em; color: #434343; line-height: 1.5; display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; width: 100%; -webkit-line-clamp: 2; line-clamp: 2; }

@media all and (max-width: 768px) { .widget_popular--txt .tit { font-weight: bold; } }

@media all and (min-width: 769px) { .widget_popular--txt .tit { font-size: 13px; } }

.widget_popular--txt .cate { font-size: 11px; color: #ff8f3c; border: 1px solid #ff8f3c; display: inline-block; padding: 2px 8px; }

@media all and (max-width: 768px) { .widget_popular--txt .cate { font-size: 12px; padding: 3px 15px; margin-bottom: 7px; } }

.widget_ranking { overflow-x: hidden; }

@media all and (min-width: 769px) { .widget_ranking .widget-title.dissp { display: none !important; } }

.widget_ranking--tablist { margin-top: 20px; }

.widget_ranking--tablist .slick-list { border-bottom: 12px solid #00a2d3; }

@media all and (max-width: 768px) { .widget_ranking--tablist { width: calc(100% + 3px); } }

@media all and (min-width: 769px) { .widget_ranking--tablist { display: none !important; } }

.widget_ranking--tabitem { background: #7acae9; margin-right: 3px; border-top-left-radius: 10px; border-top-right-radius: 10px; cursor: pointer; }

.widget_ranking--tabitem span { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; width: 100%; text-align: center; font-weight: bold; height: 50px; color: #fff; }

.widget_ranking--tabitem.slick-current { background: #00a2d3; }

.widget_ranking--tab { background: #fff; }

@media all and (min-width: 769px) { .widget_ranking--tab { margin-bottom: 14px; } }

@media all and (max-width: 768px) { .widget_ranking--tab .widget-title { margin: 0; }
  .widget_ranking--tab .widget-title--tab { display: none; } }

@media all and (min-width: 769px) { .widget_ranking__cont { border: 1px solid #d1eaf7; padding: 5px 15px; } }

.widget_ranking--item { position: relative; border-bottom: 1px dotted #999; }

@media all and (max-width: 768px) { .widget_ranking--item { border-bottom: 1px solid #ccc; position: relative; }
  .widget_ranking--item::after { position: absolute; content: '\f054'; font-family: FontAwesome; font-size: 12px; right: 17px; top: 50%; margin-top: -6px; color: #7acae9; font-weight: normal; }
  .widget_ranking--item::after { color: #ccc; right: 12px; } }

.widget_ranking--item:last-child { border-bottom: none; }

.widget_ranking--item:nth-child(1)::before { content: ''; position: absolute; background: url(../img/common/icon/icon-no1.png) no-repeat; background-size: 30px; width: 30px; height: 37px; top: 15px; left: 0; }

@media all and (max-width: 768px) { .widget_ranking--item:nth-child(1)::before { height: 100%; top: 0; } }

@media all and (max-width: 768px) { .widget_ranking--item:nth-child(1)::before { background: #d4ba60 url(../img/common/icon/icon-txtno1.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(2)::before { background: #c0c0c0 url(../img/common/icon/icon-txtno2.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(3)::before { background: #bf8e8e url(../img/common/icon/icon-txtno3.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(4)::before { background: #87bbca url(../img/common/icon/icon-txtno4.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(5)::before { background: #87bbca url(../img/common/icon/icon-txtno5.png) no-repeat center center; background-size: 15px; } }

.widget_ranking--item:nth-child(2)::before { content: ''; position: absolute; background: url(../img/common/icon/icon-no2.png) no-repeat; background-size: 30px; width: 30px; height: 37px; top: 15px; left: 0; }

@media all and (max-width: 768px) { .widget_ranking--item:nth-child(2)::before { height: 100%; top: 0; } }

@media all and (max-width: 768px) { .widget_ranking--item:nth-child(1)::before { background: #d4ba60 url(../img/common/icon/icon-txtno1.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(2)::before { background: #c0c0c0 url(../img/common/icon/icon-txtno2.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(3)::before { background: #bf8e8e url(../img/common/icon/icon-txtno3.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(4)::before { background: #87bbca url(../img/common/icon/icon-txtno4.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(5)::before { background: #87bbca url(../img/common/icon/icon-txtno5.png) no-repeat center center; background-size: 15px; } }

.widget_ranking--item:nth-child(3)::before { content: ''; position: absolute; background: url(../img/common/icon/icon-no3.png) no-repeat; background-size: 30px; width: 30px; height: 37px; top: 15px; left: 0; }

@media all and (max-width: 768px) { .widget_ranking--item:nth-child(3)::before { height: 100%; top: 0; } }

@media all and (max-width: 768px) { .widget_ranking--item:nth-child(1)::before { background: #d4ba60 url(../img/common/icon/icon-txtno1.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(2)::before { background: #c0c0c0 url(../img/common/icon/icon-txtno2.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(3)::before { background: #bf8e8e url(../img/common/icon/icon-txtno3.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(4)::before { background: #87bbca url(../img/common/icon/icon-txtno4.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(5)::before { background: #87bbca url(../img/common/icon/icon-txtno5.png) no-repeat center center; background-size: 15px; } }

.widget_ranking--item:nth-child(4)::before { content: ''; position: absolute; background: url(../img/common/icon/icon-no4.png) no-repeat; background-size: 30px; width: 30px; height: 37px; top: 15px; left: 0; }

@media all and (max-width: 768px) { .widget_ranking--item:nth-child(4)::before { height: 100%; top: 0; } }

@media all and (max-width: 768px) { .widget_ranking--item:nth-child(1)::before { background: #d4ba60 url(../img/common/icon/icon-txtno1.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(2)::before { background: #c0c0c0 url(../img/common/icon/icon-txtno2.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(3)::before { background: #bf8e8e url(../img/common/icon/icon-txtno3.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(4)::before { background: #87bbca url(../img/common/icon/icon-txtno4.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(5)::before { background: #87bbca url(../img/common/icon/icon-txtno5.png) no-repeat center center; background-size: 15px; } }

.widget_ranking--item:nth-child(5)::before { content: ''; position: absolute; background: url(../img/common/icon/icon-no5.png) no-repeat; background-size: 30px; width: 30px; height: 37px; top: 15px; left: 0; }

@media all and (max-width: 768px) { .widget_ranking--item:nth-child(5)::before { height: 100%; top: 0; } }

@media all and (max-width: 768px) { .widget_ranking--item:nth-child(1)::before { background: #d4ba60 url(../img/common/icon/icon-txtno1.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(2)::before { background: #c0c0c0 url(../img/common/icon/icon-txtno2.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(3)::before { background: #bf8e8e url(../img/common/icon/icon-txtno3.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(4)::before { background: #87bbca url(../img/common/icon/icon-txtno4.png) no-repeat center center; background-size: 15px; }
  .widget_ranking--item:nth-child(5)::before { background: #87bbca url(../img/common/icon/icon-txtno5.png) no-repeat center center; background-size: 15px; } }

.widget_ranking--item a { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: center; flex-wrap: nowrap; padding: 10px 0; }

@media all and (max-width: 768px) { .widget_ranking--item a { padding: 12px 30px 12px 40px; } }

.widget_ranking--item a:hover { opacity: 0.7; }

.widget_ranking--img { min-width: 100px; flex: 0 0 100px; max-width: 100px; }

.widget_ranking--txt { text-align: left; padding: 0 0 0 50px; }

@media all and (max-width: 768px) { .widget_ranking--txt { padding-left: 15px; } }

.widget_ranking--txt .name { display: block; width: 100%; color: #4072d7; font-size: 16px; text-decoration: underline; }

@media all and (max-width: 768px) { .widget_ranking--txt .name { font-weight: bold; text-decoration: none; font-size: 15px; color: #000; } }

.widget_ranking--txt .description { font-size: 12px; text-align: justify; height: 3em; line-height: 1.5; display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; width: 100%; -webkit-line-clamp: 2; line-clamp: 2; }

@media all and (min-width: 769px) { .widget_ranking--txt .description { display: none; } }

.widget_ranking .linklist { margin: 5px 0 15px; text-align: right; }

@media all and (max-width: 768px) { .widget_ranking .linklist { display: none; } }

.widget_ranking .linklist a.alink { word-break: break-all; word-wrap: break-word; padding-right: 10px; padding-left: 15px; color: #4072d7; text-decoration: underline; font-size: 14px; position: relative; }

.widget_ranking .linklist a.alink::before { content: ""; display: block; width: 8px; height: 14px; background: url(../img/common/icon/arr-blue.png) no-repeat; background-size: 8px; position: absolute; top: 3px; bottom: 0; left: 0; margin: auto 0; }

.widget_ranking .linklist a.alink:hover { text-decoration: none; }

.widget_ranking--star { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: center; margin: 8px 0 0; }

@media all and (max-width: 768px) { .widget_ranking--star { position: relative; margin: 4px 0; } }

.widget_ranking--star .rating-box { line-height: 1; position: relative; white-space: nowrap; display: inline-block; }

.widget_ranking--star .rating-box span { display: block; position: absolute; top: 0; left: 0; bottom: 0; z-index: 1; overflow: hidden; line-height: 1; }

.widget_ranking--star .rating-box .fa-star { font-family: fontAwesome; font-weight: 400; font-style: normal; display: inline-block; margin: .1rem; margin-left: 0; color: #C1C1C1; text-rendering: auto; font-size: 12px; }

.widget_ranking--star .rating-box .fa-star:first-child { margin-left: 0; }

.widget_ranking--star .rating-box span .fa-star { color: #fbd261 !important; position: relative; }

.widget_ranking--star .rating-box span .fa-star::after { position: absolute; content: ''; background: url(../img/common/icon/icon-start.png) no-repeat; background-size: 11px; left: 0; top: 0; width: 11px; height: 11px; z-index: 9; }

@media all and (max-width: 768px) { .widget_ranking--star .rating-box span .fa-star::after { background-size: 9px; } }

.widget_ranking--star .rating-num { font-weight: bold; margin-left: 10px; display: inline-block; color: #ff4200; font-size: 14px; }

@media all and (max-width: 768px) { .widget_ranking--star .rating-num { font-size: 12px; line-height: 1; } }

.widget_ranking--btn a { font-size: 12px; max-width: 220px; }

.widget_pickup { margin-top: 20px; }

@media all and (max-width: 768px) { .widget_pickup { margin-top: 50px; } }

@media all and (min-width: 769px) { .widget_pickup .widget-title { border-bottom: 0; padding-bottom: 0; margin-bottom: 20px; background: none; border-left: 8px solid #02a3d4; min-height: 32px; }
  .widget_pickup .widget-title h3 { font-weight: bold; font-size: 20px; color: #02a3d4; text-align: left; line-height: 28px; height: 32px; padding: 0; padding-left: 18px; } }

.widget_pickup p { margin: 0 auto; margin-bottom: 14px; text-align: center; }

.widget_pickup p img { margin: 0 auto; }

@media all and (min-width: 768px) { .widget_pickup p { max-width: 250px; } }

.Footer { background: #fff; }

.Footer .FooterNav { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; flex-wrap: wrap; margin-top: 22px; }

.Footer .FooterNav li { margin: 0 13px; list-style: none; }

@media all and (max-width: 768px) { .Footer .FooterNav li { margin: 0; margin-right: 15px; }
  .Footer .FooterNav li:nth-child(3), .Footer .FooterNav li:last-child { margin-right: 0; }
  .Footer .FooterNav li:nth-child(3) { page-break-after: always; margin-right: 0; } }

.Footer .FooterNav a { color: #4072d7; font-size: 14px; }

.Footer .FooterNav a:last-child { margin-right: 0; }

@media all and (max-width: 768px) { .Footer .FooterNav a { padding: 7px 0; margin-right: 20px; font-size: 12px; display: block; } }

@media all and (min-width: 769px) { .Footer .FooterNav a { margin-right: 25px; position: relative; padding: 5px 0; }
  .Footer .FooterNav a::after { position: absolute; content: ""; transition: all 0.35s ease; bottom: 1px; display: block; height: 1px; width: 0%; background-color: #4072d7; left: 0; }
  .Footer .FooterNav a:hover::after { opacity: 1; width: 100%; } }

.Footer .ShareNav { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; }

.Footer .ShareNav a { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; margin-right: 15px; }

.Footer .ShareNav a img { width: 37px; height: 37px; }

@media all and (max-width: 768px) { .Footer .ShareNav a img { width: 25px; height: 25px; } }

.Footer .ShareNav a:last-child { margin-right: 0; }

@media all and (min-width: 769px) { .Footer .ShareNav a { margin-right: 20px; }
  .Footer .ShareNav a:hover { opacity: 0.7; } }

.Footer .copyright { background: #02a3d4; height: 70px; margin-top: 33px; }

@media all and (max-width: 768px) { .Footer .copyright { margin-top: 15px; height: 50px; } }

.Footer .copyright p { font-size: 11px; text-align: center; color: #fff; line-height: 70px; letter-spacing: 1px; }

@media all and (max-width: 768px) { .Footer .copyright p { line-height: 50px; } }

.Footer .page_top_cont { width: 100%; max-width: 100%; position: fixed; z-index: 990; bottom: 15px; right: 0; }

.Footer .page_top_cont .page_top .to_top { cursor: pointer; display: block; position: absolute; bottom: 0; right: 15px; zoom: 1; text-align: right; z-index: 999; }

.Footer .page_top_cont .page_top .to_top img { width: 45px; height: 45px; }

.Footer__common { margin-top: 60px; }

@media all and (max-width: 768px) { .Footer__common { margin-top: 40px; } }

.Footer__about { background: url(../img/common/background_sec_pc.jpg) no-repeat center center; background-size: cover; height: 442px; }

@media all and (max-width: 768px) { .Footer__about { background: url(../img/common/background_sec_sp.jpg) no-repeat center center; padding: 40px; height: auto; } }

@media all and (min-width: 769px) { .Footer__about .flexbox { flex-wrap: nowrap; min-height: 442px; height: 442px; } }

@media all and (max-width: 768px) { .Footer__about--img { flex: 0 0 100%; text-align: center; margin-bottom: 24px; }
  .Footer__about--img img { max-width: 165px; margin: 0 auto; } }

@media all and (min-width: 769px) { .Footer__about--img { flex: 0 0 327px; max-width: 32%; margin-top: -44px; } }

.Footer__about--desc p { font-size: 18px; font-weight: 700; margin: 26px 0; text-align: justify; color: #000; }

.Footer__about--desc p span { color: #02a3d4; font-weight: 700; }

.Footer__about--desc p:first-child { margin-top: 0; }

.Footer__about--desc p:last-child { margin-bottom: 0; }

@media all and (max-width: 768px) { .Footer__about--desc { flex: 0 0 100%; }
  .Footer__about--desc p { font-size: 12px; } }

@media all and (min-width: 769px) { .Footer__about--desc { width: calc(100% - 380px); max-width: 68%; } }

.Footer__bnr { padding: 60px 0; background: #fff; }

@media all and (max-width: 768px) { .Footer__bnr { padding: 40px 20px; } }

.Footer__bnr--area { flex: 0 0 48.5%; }

@media all and (max-width: 768px) { .Footer__bnr--area { flex: 0 0 100%; margin-bottom: 15px; } }

.Footer__bnr--area img { width: 100%; }

.Footer__bnr--area:nth-child(3) { flex: 0 0 100%; margin-top: 30px; }

@media all and (max-width: 768px) { .Footer__bnr--area:nth-child(3) { margin: 0; } }

@media all and (min-width: 769px) and (max-width: 1080px) { html { overflow-x: scroll; } }

@media all and (min-width: 769px) and (max-width: 1080px) { body { min-width: 1080px; width: 1080px; width: 100%; overflow-x: scroll; } }

body { font-family: 'Noto Sans JP'; font-size: 14px; font-weight: 500; color: #434343; }

@media all and (min-width: 769px) { body { background: #ebf8ff; } }

body p, body a { line-height: normal; color: #434343; }

body .img { overflow: hidden; line-height: 0; }

body .img img { width: 100%; height: 100%; transform: scale(1); transition-duration: 4s; -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); -o-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); -webkit-transition-property: -webkit-transform; -o-transition-property: transform; transition-property: transform, -webkit-transform; }

.container { max-width: 1080px; margin: 0 auto; }

@media all and (max-width: 1080px) { .container { max-width: 1200px; } }

@media all and (max-width: 768px) { .container { padding: 0; max-width: 100%; } }

.container.flexwrap .content { background: #fff; padding: 30px 15px 50px; }

@media all and (min-width: 769px) { .container.flexwrap { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: space-between; align-items: flex-start; }
  .container.flexwrap .content { width: 74%; max-width: 800px; background: #fff; border-radius: 10px; padding: 40px; }
  .container.flexwrap aside { width: 245px; } }

.flexbox { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; }

.flexbox_start { justify-content: flex-start; }

.flexbox_end { justify-content: flex-end; }

.flexbox_center { justify-content: center; }

button { border: none; outline: none; cursor: pointer; }

.button { transition: all 0.3s; background: #00a2d3; padding: 20px 0; }

.button a { display: block; margin: 0 auto; border-radius: 99px; border: 2px solid #fff; font-weight: bold; text-align: center; padding: 14px 25px; background: #ffea00; position: relative; }

.button a::after { position: absolute; content: '\f054'; font-family: FontAwesome; font-size: 12px; right: 17px; top: 50%; margin-top: -6px; color: #7acae9; font-weight: normal; }

.pagination { margin: 50px 0 30px; }

@media all and (max-width: 768px) { .pagination { margin: 20px 0 10px; } }

.pagination ul { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; }

.pagination ul li { font-size: 12px; margin: 0 2px; }

.pagination ul li a { font-size: 12px; background: #fff; border: 1px solid #e9e9e9; display: block; text-align: center; width: 60px; height: 60px; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; color: #02a3d4; }

.pagination ul li a:hover { background: #02a3d4; color: #fff; }

.pagination ul li span.current { width: 60px; height: 60px; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; background: #02a3d4; color: #fff; }

@media all and (max-width: 520px) { .blockhead { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap; } }

.blockhead--title { font-size: 28px; font-weight: 600; margin-top: 0; margin-bottom: 30px; text-align: justify; width: 100%; border-left: 10px solid #00a2d3; padding: 2px 0 6px; padding-left: 20px; }

@media all and (max-width: 768px) { .blockhead--title { font-size: 20px; border-left: 5px solid #00a2d3; padding-left: 15px; margin-bottom: 15px; } }

.blockhead .blocksocial { margin: 30px 0; }

@media all and (max-width: 768px) { .blockhead .blocksocial { margin-top: 0; margin-bottom: 15px; } }

.blocktitle { border-left: 5px solid #02a3d4; padding-bottom: 10px; }

@media all and (min-width: 769px) { .blocktitle { border-left: 2x solid #02a3d4; } }

.blocktitle--listtitle { font-weight: bold; font-size: 21px; border-left: 0; border-bottom: 5px solid #02a3d4; margin-bottom: 20px; }

@media all and (max-width: 768px) { .blocktitle--listtitle { font-size: 15px; } }

.blockrecomend { margin: 50px 0; }

@media all and (max-width: 768px) { .blockrecomend { margin: 40px 0 35px; } }

.blockrecomend__list--item { margin: 20px 0; }

@media all and (max-width: 768px) { .blockrecomend__list--item { margin-bottom: 30px; }
  .blockrecomend__list--item:last-child { margin-bottom: 0; } }

.blockrecomend__list--item a { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: nowrap; }

@media all and (min-width: 769px) { .blockrecomend__list--item a:hover { opacity: 0.7; }
  .blockrecomend__list--item a:hover img { opacity: 1; } }

.blockrecomend__list--img { max-width: 140px; width: 140px; }

@media all and (max-width: 768px) { .blockrecomend__list--img { min-width: 100px; max-width: 100px; } }

.blockrecomend__list--text { width: calc(100% - 160px); font-weight: bold; margin-left: 20px; text-align: justify; font-size: 18px; }

@media all and (max-width: 768px) { .blockrecomend__list--text { width: calc(100% - 120px); font-size: 16px; max-height: 6em; display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; width: 100%; -webkit-line-clamp: 4; line-clamp: 4; } }

.blockcate__list { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; }

.blockcate__list--item { flex: 0 0 25%; max-width: 170px; margin-bottom: 15px; }

@media all and (max-width: 768px) { .blockcate__list--item { flex: 0 0 48%; margin-bottom: 10px; max-width: inherit; } }

.blockcate__list--item a { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: center; border: 2px solid #02a3d4; border-radius: 10px; overflow: hidden; }

@media all and (min-width: 769px) { .blockcate__list--item a:hover { opacity: 0.7; }
  .blockcate__list--item a:hover img { opacity: 1; } }

.blockcate__list--img { width: 50px; }

.blockcate__list--text { font-size: 14px; color: #02a3d4; width: calc(100% - 60px); margin-left: 10px; }

.bnrareabottom { padding: 0 5px; margin: 50px 0 0; }

.bnrareabottom .Footer__bnr--area:last-child { margin-bottom: 0; }

.purchasebox { background: #00a2d3; padding: 20px 10px 30px; margin: 50px 0; }

@media all and (max-width: 768px) { .purchasebox { padding: 20px 25px; margin: 40px 0 35px; } }

.purchasebox--title { color: #fff; font-weight: bold; font-size: 24px; text-align: center; border-bottom: 1px solid #fff; padding-bottom: 10px; line-height: 1; }

@media all and (max-width: 768px) { .purchasebox--title { display: none; } }

.purchasebox--subtitle { margin: 25px auto 30px; }

@media all and (max-width: 768px) { .purchasebox--subtitle { margin-top: 0; margin-bottom: 15px; } }

.purchasebox--subtitle img { margin: 0 auto; }

.purchasebox--search { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: space-between; align-items: center; margin: 15px 30px; }

@media all and (max-width: 768px) { .purchasebox--search { flex-wrap: wrap; margin: 15px 0; } }

.purchasebox--search .button { padding: 0; }

.purchasebox--search .search--select { position: relative; }

.purchasebox--search .search--select::after { position: absolute; content: '\f054'; font-family: FontAwesome; font-size: 12px; right: 17px; top: 50%; margin-top: -6px; color: #7acae9; font-weight: normal; }

.purchasebox--search .search--select::after { right: 19px; transform: rotate(90deg); }

@media all and (min-width: 769px) { .purchasebox--search .search--select { min-width: 345px; width: 345px; } }

@media all and (max-width: 768px) { .purchasebox--search .search--select { flex: 0 0 100%; margin-bottom: 15px; } }

.purchasebox--search .search--select select { background: #fff; border: 2px solid #ffea00; font-size: 18px; width: 100%; height: 70px; padding: 0 30px; -webkit-appearance: none; -moz-appearance: none; appearance: none; }

@media all and (max-width: 768px) { .purchasebox--search .search--select select { font-size: 12px; height: 50px; padding: 0 15px; } }

@media all and (min-width: 769px) { .purchasebox--search .search--button { min-width: 280px; width: 280px; } }

@media all and (max-width: 768px) { .purchasebox--search .search--button { flex: 0 0 100%; } }

.purchasebox--search .search--button a { font-size: 18px; padding: 20px 25px; }

@media all and (max-width: 768px) { .purchasebox--search .search--button a { font-size: 15px; padding: 12px 25px; } }

.purchasebox--icon { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; }

.purchasebox--icon li { max-width: 102px; margin: 0 10px; width: 102px; }

@media all and (max-width: 768px) { .purchasebox--icon li { margin: 0 5px; } }

.blocksocial { margin: 50px 0; width: 100%; }

@media all and (max-width: 768px) { .blocksocial { margin: 35px 0; } }

.blocksocial ul { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: flex-start; width: 100%; }

.blocksocial li { margin-right: 20px; text-align: center; }

@media all and (max-width: 768px) { .blocksocial li { margin-right: 15px; } }

.blocksocial li img { margin: 0 auto; }

.blocksocial.socialshare li { width: 35px; height: 35px; }

@media all and (max-width: 768px) { .blocksocial.socialshare li { width: 25px; height: 25px; } }

.blocksocial.socialshare li img { max-height: 35px; }

@media all and (max-width: 768px) { .blocksocial.socialshare li img { max-height: 25px; } }

.blocksocial.socialauthor ul { justify-content: flex-end; }

.blocksocial.socialauthor li { width: 25px; height: 25px; }

@media all and (max-width: 768px) { .blocksocial.socialauthor li { width: 20px; height: 20px; } }

.blocksocial.socialauthor li:last-child { margin-right: 0; }

.blocksocial.socialauthor li img { max-height: 25px; }

@media all and (max-width: 768px) { .blocksocial.socialauthor li img { max-height: 20px; } }

.block-profile { margin: 50px 0; }

@media all and (max-width: 768px) { .block-profile { margin: 40px 0 35px; } }

.block-profile--title { font-size: 21px; font-weight: bold; border-bottom: 5px solid #00a2d3; color: #434343; padding-bottom: 12px; line-height: 1; }

@media all and (max-width: 768px) { .block-profile--title { font-size: 15px; } }

.block-profile--list { margin: 30px 0; }

@media all and (max-width: 768px) { .block-profile--list { margin: 20px 0 35px; } }

.block-profile--item { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: flex-start; }

.block-profile--item .imgbox { width: 130px; height: 130px; min-width: 130px; overflow: hidden; border-radius: 99px; margin-right: 30px; }

@media all and (max-width: 768px) { .block-profile--item .imgbox { width: 82px; height: 82px; min-width: 82px; margin-right: 15px; } }

.block-profile--item .txtwarp .namearea { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: flex-start; margin-bottom: 20px; }

@media all and (max-width: 768px) { .block-profile--item .txtwarp .namearea { flex-wrap: wrap; margin-bottom: 15px; } }

.block-profile--item .txtwarp .namearea .name { font-size: 18px; font-weight: bold; margin-bottom: 0; line-height: 1; margin-right: 30px; }

@media all and (max-width: 768px) { .block-profile--item .txtwarp .namearea .name { font-size: 14px; margin-right: 15px; margin-bottom: 10px; } }

.block-profile--item .txtwarp .namearea .socialauthor { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: center; margin: 0; }

@media all and (max-width: 768px) { .block-profile--item .txtwarp .namearea .socialauthor { margin-left: 10px; } }

.block-profile--item .txtwarp .namearea .socialauthor li { width: 18px; height: 18px; max-height: 18px; text-align: center; margin: 0; margin-right: 12px; }

@media all and (max-width: 768px) { .block-profile--item .txtwarp .namearea .socialauthor li { width: 16px; height: 16px; } }

.block-profile--item .txtwarp .namearea .socialauthor li img { max-height: 18px; }

@media all and (max-width: 768px) { .block-profile--item .txtwarp .namearea .socialauthor li img { max-height: 16px; } }

.block-profile--item .txtwarp .namearea .belongs { color: #a3a3a3; font-weight: bold; font-size: 12px; }

@media all and (max-width: 768px) { .block-profile--item .txtwarp .namearea .belongs { margin-bottom: 10px; } }

.block-profile--item .txtwarp .namearea a.link { color: #0000ee; text-decoration: underline; font-weight: bold; font-size: 12px; margin: 0; margin-left: 20px; }

@media all and (max-width: 768px) { .block-profile--item .txtwarp .namearea a.link { font-size: 13px; display: block; flex: 0 0 100%; text-align: left; margin-left: 0; } }

.block-profile--item .txtwarp .namearea a.link:hover { text-decoration: none; opacity: 0.7; }

.block-profile--item .txtwarp .txtarea { text-align: justify; }

.block-profile--item .txtwarp .link { margin-top: 20px; text-align: right; }

@media all and (max-width: 768px) { .block-profile--item .txtwarp .link { margin-top: 10px; } }

.block-profile--item .txtwarp .link a { font-size: 14px; color: #4072d7; text-decoration: underline; }

@media all and (min-width: 769px) { .block-profile--item .txtwarp .link a:hover { opacity: 0.7; }
  .block-profile--item .txtwarp .link a:hover img { opacity: 1; } }

.block-profile--item .txtwarp .link a:hover { text-decoration: none; }

.block-profile--boxboder { background: #ebf8ff; border-radius: 8px; border: 1px solid #ccc; padding: 25px 20px; padding-right: 35px; }

@media all and (max-width: 768px) { .block-profile--boxboder { padding-right: 20px; } }

.block-profile--boxboder .block-profile--list { margin: 0; margin-bottom: 30px; }

@media all and (max-width: 768px) { .block-profile--boxboder .block-profile--list { margin-bottom: 20px; } }

.block-profile--boxboder .block-profile--item .imgbox { width: 120px; height: 120px; min-width: 120px; }

@media all and (max-width: 768px) { .block-profile--boxboder .block-profile--item .imgbox { width: 90px; height: 90px; min-width: 90px; } }

.single .post-thumbnail { margin: 30px 0; }

@media all and (max-width: 768px) { .single .post-thumbnail { margin: 0; margin-bottom: 15px; } }

@media all and (min-width: 769px) { .single__detail { margin-top: 30px; } }

.single__detail p, .single__detail dl, .single__detail dt, .single__detail dd, .single__detail td, .single__detail th, .single__detail div, .single__detail li, .single__detail blockquote { font-size: 16px; line-height: 1.6; }

@media all and (max-width: 768px) { .single__detail p, .single__detail dl, .single__detail dt, .single__detail dd, .single__detail td, .single__detail th, .single__detail div, .single__detail li, .single__detail blockquote { font-size: 15px; } }

.single__detail h2, .single__detail h3, .single__detail h4, .single__detail h5, .single__detail h6 { font-weight: bold; }

.single__detail h2 span, .single__detail h3 span, .single__detail h4 span, .single__detail h5 span, .single__detail h6 span { font-weight: bold; }

.single__detail h2 { font-size: 26px; margin: 65px 0 40px; border-left: 10px solid #00a2d3; padding-left: 20px; }

@media all and (max-width: 768px) { .single__detail h2 { font-size: 20px; border-left: 5px solid #00a2d3; padding-left: 15px; margin-top: 50px; margin-bottom: 40px; } }

.single__detail h3 { background: #ebf8ff; padding: 16px 20px; font-size: 21px; margin: 50px 0 30px; }

@media all and (max-width: 768px) { .single__detail h3 { font-size: 17px; padding: 10px 20px; margin: 45px 0 25px; } }

.single__detail h4 { color: #00a2d3; font-size: 19px; margin: 50px 0 30px; }

@media all and (max-width: 768px) { .single__detail h4 { font-size: 17px; margin: 45px 0 25px; } }

.single__detail h5, .single__detail h6 { font-size: 17px; margin-bottom: 10px; }

.single__detail [data-rank] { border-left: 10px solid #00a2d3; margin-bottom: 12px; background-color: #f0f5ff; padding: 16px 16px 14.5px 55px; box-sizing: border-box; min-height: 58px; color: #434343; font-weight: bold; font-size: 18px; line-height: 1.5; background-repeat: no-repeat; background-size: 30px; background-position: center left 15px; position: relative; margin-top: 0; }

@media all and (max-width: 768px) { .single__detail [data-rank] { font-size: 17px; margin-bottom: 15px; } }

.single__detail [data-rank="1"] { background-image: url(../img/common/icon/icon-no1.png); }

.single__detail [data-rank="2"] { background-image: url(../img/common/icon/icon-no2.png); }

.single__detail [data-rank="3"] { background-image: url(../img/common/icon/icon-no3.png); }

.single__detail [data-rank="4"] { background-image: url(../img/common/icon/icon-no4.png); }

.single__detail [data-rank="5"] { background-image: url(../img/common/icon/icon-no5.png); }

.single__detail [data-rank="6"] { background-image: url(../img/common/icon/icon-no6.png); }

.single__detail [data-rank="7"] { background-image: url(../img/common/icon/icon-no7.png); }

.single__detail [data-rank="8"] { background-image: url(../img/common/icon/icon-no8.png); }

.single__detail [data-rank="9"] { background-image: url(../img/common/icon/icon-no9.png); }

.single__detail [data-rank="10"] { background-image: url(../img/common/icon/icon-no10.png); }

.single__detail [data-rank="11"] { background-image: url(../img/common/icon/icon-no11.png); }

.single__detail div, .single__detail p { text-align: justify; }

.single__detail p { margin-bottom: 20px; }

@media all and (max-width: 768px) { .single__detail p { margin-bottom: 15px; } }

.single__detail p.mt30 { margin-top: 30px; }

.single__detail ul, .single__detail ol { margin: 30px 0; }

.single__detail ul li, .single__detail ol li { position: relative; list-style: none; margin-bottom: 10px; }

@media all and (max-width: 768px) { .single__detail ul li, .single__detail ol li { margin-bottom: 15px; } }

.single__detail ul li:last-child, .single__detail ol li:last-child { margin-bottom: 0; }

.single__detail ul.liststyle--num { counter-reset: item; }

.single__detail ul.liststyle--num li::before { content: counter(item); counter-increment: item; color: #00a2d3; margin-right: 10px; margin-left: 5px; }

.single__detail ul.liststyle--check li { padding-left: 33px; }

.single__detail ul.liststyle--check li::before { position: absolute; content: ''; background: url(../img/common/icon/icon-check.png) no-repeat left center; background-size: 20px; width: 20px; height: 20px; left: 0; top: 50%; margin-top: -10px; }

.single__detail ul.liststyle--dot li::before { content: "・"; margin-right: 5px; }

.single__detail ul.liststyle--arrow li { position: relative; padding-left: 20px; }

.single__detail ul.liststyle--arrow li::after { position: absolute; content: '\f054'; font-family: FontAwesome; font-size: 12px; right: 17px; top: 50%; margin-top: -6px; color: #7acae9; font-weight: normal; }

.single__detail ul.liststyle--arrow li::after { right: inherit; left: 5px; }

.single__detail table { width: 100%; margin-bottom: 30px; table-layout: fixed; }

@media all and (max-width: 768px) { .single__detail table { margin-top: 35px; } }

.single__detail table tr:first-child th, .single__detail table tr:first-child td { font-weight: bold; }

@media all and (max-width: 768px) { .single__detail table tr:first-child th, .single__detail table tr:first-child td { padding: 7px 10px; } }

.single__detail table th, .single__detail table td { vertical-align: middle; text-align: center; padding: 16px 15px; border: 1px solid #ccc; font-size: 15px; }

.single__detail table th:first-child, .single__detail table td:first-child { font-weight: bold; background: #f2f2f2; width: 55%; }

@media all and (max-width: 768px) { .single__detail table th:first-child, .single__detail table td:first-child { width: 45%; } }

@media all and (max-width: 768px) { .single__detail table th, .single__detail table td { padding: 15px 10px; } }

.single__detail table.is-style-org tr:first-child td { background: #ff8f3c; color: #fff; }

.single__detail table.is-style-blue tr:first-child td { background: #7acae9; color: #fff; }

.single__detail table.is-style-normal th:first-child, .single__detail table.is-style-normal td:first-child { background: #eaeaea; }

@media all and (max-width: 768px) { .single__detail table.is-style-normal th:first-child, .single__detail table.is-style-normal td:first-child { padding: 15px 10px; } }

.single__detail table.is-style-normal tr:nth-child(odd) { background: #f8f8f8; }

.single__detail blockquote { background: #f7f7f7; padding: 40px; margin-top: 50px; text-align: justify; position: relative; }

@media all and (max-width: 768px) { .single__detail blockquote { padding: 30px 25px; } }

.single__detail blockquote::before, .single__detail blockquote::after { position: absolute; content: ''; background: url(../img/common/icon/icon-quotes.png) no-repeat; background-size: 100%; width: 33px; height: 25px; }

.single__detail blockquote::before { left: 20px; top: -12px; }

@media all and (max-width: 768px) { .single__detail blockquote::before { left: 15px; } }

.single__detail blockquote::after { right: 20px; bottom: -12px; transform: rotate(180deg); }

@media all and (max-width: 768px) { .single__detail blockquote::after { right: 15px; } }

.single__detail a { text-decoration: underline; color: #4072d7; }

.single__detail a:hover { text-decoration: none; }

.single__detail a span { color: #4072d7; }

.single__detail img.lazy-loaded { background-size: 180px !important; }

.single__detail img.noloadbg { background: none !important; }

.single__detail .btnarea { margin: 50px 0; }

@media all and (max-width: 768px) { .single__detail .btnarea { margin: 45px 0; } }

.single__detail .btndetail { overflow: hidden; border-radius: 99px; margin: 20px 0; }

.single__detail .btndetail a { font-weight: bold; display: block; text-align: center; position: relative; color: #fff; border-radius: 99px; text-decoration: none; padding: 18px 50px; }

.single__detail .btndetail a::before { content: ''; position: absolute; position: absolute; top: 50%; width: 8px; height: 8px; background: transparent; border-top: 3px solid #fff; border-right: 3px solid #fff; transition: all .3s; width: 7px; height: 7px; right: 0; top: 50%; margin-top: -4px; transform: rotate(45deg); }

.single__detail .btndetail a span { font-weight: bold; color: #fff; }

.single__detail .btndetail a::before { right: 30px; }

@media all and (min-width: 769px) { .single__detail .btndetail a:hover { opacity: 0.7; } }

.single__detail .btndetail--green { background: #53c267; }

.single__detail .btndetail--blue { background: #1bc0c7; }

.single__detail .btndetail--white { background: #fff; border: 2px solid #cdcdcd; }

.single__detail .btndetail--white a { color: #00a2d3; }

.single__detail .btndetail--white a::before { border-top: 3px solid #cdcdcd; border-right: 3px solid #cdcdcd; }

.single__detail .listpage { background: #ebf8ff; padding: 25px 20px; margin: 50px 0; }

.single__detail .listpage .openlist { position: relative; height: 220px; transition: max-height .25s; overflow: hidden; }

.single__detail .listpage .openlist::after { content: ''; position: absolute; bottom: 0; left: 0; z-index: 2; width: 100%; height: 50%; background: -moz-linear-gradient(bottom, #ebf8ff, rgba(228, 243, 255, 0)); background: -webkit-linear-gradient(bottom, #ebf8ff, rgba(228, 243, 255, 0)); background: linear-gradient(to top, #ebf8ff, rgba(228, 243, 255, 0)); }

.single__detail .listpage .openlist.active { overflow: visible; }

.single__detail .listpage .openlist.active::after { background: none; }

@media all and (max-width: 768px) { .single__detail .listpage { padding: 20px; margin: 50px 0 35px; } }

.single__detail .listpage__title { line-height: 1; }

@media all and (max-width: 768px) { .single__detail .listpage__title { font-size: 17px; } }

.single__detail .listpage__title a { font-weight: bold; text-decoration: none; line-height: 1; color: #00a2d3; }

.single__detail .listpage__list { margin-top: 20px; margin-bottom: 0; }

@media all and (max-width: 768px) { .single__detail .listpage__list { margin-top: 10px; } }

.single__detail .listpage__list a { font-size: 14px; line-height: 1; position: relative; padding-left: 12px; }

@media all and (min-width: 769px) { .single__detail .listpage__list a:hover { opacity: 0.7; }
  .single__detail .listpage__list a:hover img { opacity: 1; } }

.single__detail .listpage__list a::before { position: absolute; content: ''; background: url(../img/common/icon/icon-list.png) no-repeat left center; background-size: 10px; width: 10px; height: 10px; left: 0; top: 8px; }

.single__detail .listpage__list li { margin-bottom: 0; margin-left: 20px; }

@media all and (max-width: 768px) { .single__detail .listpage__list li { margin-bottom: 3px; } }

.single__detail .listpage__list li:first-child { position: relative; margin-left: 8px; }

.single__detail .listpage__list li:first-child::before { content: ''; position: absolute; position: absolute; top: 50%; width: 8px; height: 8px; background: transparent; border-top: 3px solid #7acae9; border-right: 3px solid #7acae9; transition: all .3s; width: 9px; height: 9px; left: 0; top: 50%; margin-top: -4px; transform: rotate(45deg); }

.single__detail .listpage__list li:first-child::before { left: -10px; }

.single__detail .listpage__list li:first-child a:before { background: none; z-index: -1; }

.single__detail .block-text { padding: 30px 25px; border-radius: 10px; margin-bottom: 30px; text-align: justify; }

@media all and (max-width: 768px) { .single__detail .block-text { padding: 25px; } }

.single__detail .block-text ul, .single__detail .block-text ol { margin: 0; }

.single__detail .block-blue { border: 1px solid #00a2d3; background: #ebf8ff; }

.single__detail .block-pink { border: 1px solid #ff7979; background: #fff5f5; }

.single__detail .block-gray { border: 1px solid #ccc; background: #f7f7f7; }

.single__detail .block-white { border: 1px solid #ccc; background: #fff; }

.single__detail .block-point { background: #fffae8; border: 1px solid #e1af00; border-radius: 0; padding: 25px; position: relative; margin-top: 80px; }

@media all and (max-width: 768px) { .single__detail .block-point { margin-top: 60px; padding: 20px; } }

.single__detail .block-point::before { position: absolute; content: ''; background: url(../img/common/txt-point.png) no-repeat; background-size: 100%; width: 72px; height: 27px; left: -1px; top: -28px; }

.single__detail .block-link { background: #ebf8ff; padding: 30px 40px; margin-top: 50px; border-radius: 0; }

@media all and (max-width: 768px) { .single__detail .block-link { padding: 30px; } }

.single__detail .block-link p { margin-bottom: 0; }

.single__detail .block-link a { display: inline-block; }

.single__detail .block-reviews { border: 1px solid #ccc; border-radius: 8px; overflow: hidden; margin-bottom: 30px; }

.single__detail .block-reviews--tit { background: #f7f7f7; padding: 15px; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: center; }

@media all and (max-width: 768px) { .single__detail .block-reviews--tit { padding: 10px; } }

.single__detail .block-reviews--tit .imgicon { width: 55px; height: 55px; overflow: hidden; border-radius: 99px; margin-right: 20px; }

.single__detail .block-reviews--tit .name { font-weight: bold; }

.single__detail .block-reviews--info { padding: 20px; }

@media all and (max-width: 768px) { .single__detail .block-reviews--info { padding: 20px 15px; } }

.single__detail .block-reviews--info .tit { font-weight: bold; margin-bottom: 10px; }

@media all and (min-width: 769px) { .single__detail .block-reviews--info .tit { font-size: 18px; } }

.single__detail .block-reviews--info .txt { font-size: 14px; margin-bottom: 0; }

.single__detail .block-voicetalk { margin: 50px 0; padding: 0 15px; }

@media all and (max-width: 768px) { .single__detail .block-voicetalk { margin: 35px 0; padding: 0; } }

.single__detail .block-voicetalk .voicetalkarea__item { margin-bottom: 30px; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: flex-start; }

@media all and (max-width: 768px) { .single__detail .block-voicetalk .voicetalkarea__item { margin-bottom: 20px; } }

.single__detail .block-voicetalk .voicetalkarea__item:last-child { margin-bottom: 0; }

.single__detail .block-voicetalk .voicetalkarea__img { width: 100px; height: 100px; min-width: 100px; position: relative; }

@media all and (max-width: 768px) { .single__detail .block-voicetalk .voicetalkarea__img { flex: 0 0 60px; height: 60px; min-width: 60px; } }

.single__detail .block-voicetalk .voicetalkarea__img img { overflow: hidden; border-radius: 99px; }

.single__detail .block-voicetalk .voicetalkarea__txt { border-radius: 8px; position: relative; padding: 15px; background: #fff; border: 1px solid #ccc; }

@media all and (max-width: 768px) { .single__detail .block-voicetalk .voicetalkarea__txt { padding: 10px; width: calc(100% - 70px); } }

.single__detail .block-voicetalk .voicetalkarea__txt::after { position: absolute; content: ''; width: 14px; height: 26px; top: 40px; z-index: 1; }

@media all and (max-width: 768px) { .single__detail .block-voicetalk .voicetalkarea__txt::after { top: 18px; } }

.single__detail .block-voicetalk .voicetalkarea__txt p { margin-bottom: 0; }

.single__detail .block-voicetalk .voicetalkarea__left__item { margin-left: 20px; }

.single__detail .block-voicetalk .voicetalkarea__left__item::after { background: url(../img/common/icon/icon-arr.png) no-repeat; background-size: 14px; left: -14px; }

.single__detail .block-voicetalk .voicetalkarea__right { flex-direction: row-reverse; }

.single__detail .block-voicetalk .voicetalkarea__right__item { margin-right: 20px; }

.single__detail .block-voicetalk .voicetalkarea__right__item::after { background: url(../img/common/icon/icon-arr.png) no-repeat; background-size: 14px; right: -14px; transform: rotate(180deg); }

.highlighted { background: linear-gradient(0deg, #FFECB9 50%, transparent 50%); font-weight: bold !important; }

.redcolor, .has-red-color { font-weight: bold !important; color: #f50930 !important; }

.redcolor span, .has-red-color span { font-weight: bold !important; color: #f50930 !important; }

.bluecolor, .has-blue-color { font-weight: bold !important; color: #4072d7 !important; }

.bluecolor span, .has-blue-color span { font-weight: bold !important; color: #4072d7 !important; }

.btnmore .iconbox { position: relative; width: 20px; height: 20px; margin: 0 auto; }

.btnmore .iconbox span { position: absolute; left: 0; right: 0; width: 100%; height: 3px; margin: auto; background-color: #434343; transition: all .4s; }

.btnmore .iconbox span:nth-of-type(1) { top: 2.5vw; transform: rotate(0deg); }

.btnmore .iconbox span:nth-of-type(2) { top: 2.5vw; transform: rotate(90deg); }

.btnmore .txt { text-align: center; font-size: 15px; margin: 10px 0 0; letter-spacing: 0.05em; }

#toc_container { background: #f7f7f7 !important; padding: 20px 25px; margin: 50px 0; border: none !important; border-top: 1px solid #ddd; border-bottom: 1px solid #ddd; }

@media all and (max-width: 768px) { #toc_container { padding: 15px; margin: 50px 0; } }

#toc_container p.toc_title { text-align: center; font-weight: bold; margin-bottom: 15px; font-size: 18px; position: relative; }

#toc_container p.toc_title::before, #toc_container p.toc_title::after { content: '-'; margin: 0 5px; }

@media all and (max-width: 768px) { #toc_container p.toc_title { font-size: 15px; } }

#toc_container .toc_list { margin: 0; }

#toc_container .toc_list ul { margin: 0; }

#toc_container .toc_list li { list-style: none; margin-bottom: 10px; }

@media all and (max-width: 768px) { #toc_container .toc_list li { font-size: 15px; margin-bottom: 5px; } }

#toc_container .toc_list li:before { content: '・' !important; }

#toc_container .toc_list li a { text-decoration: underline; color: #4072d7; font-weight: 500; }

#toc_container .toc_list li a:hover { color: #f50930; }

#toc_container .toc_list li ul li { font-size: 14px; }

#toc_container .toc_list li ul li a { font-weight: normal; }

#toc_container .toc_list li:last-child a:after { content: '' !important; }

#toc_container .toc_list > li:nth-last-child(-n+3) > a:after { content: 'New!'; display: inline-block; color: red; margin-left: 5px; font-weight: normal; }

.slick-loading .slick-list { background: #fff url("../img/common/ajax-loader.gif") center center no-repeat; }

@font-face { font-family: "slick"; src: url("./fonts/slick.eot"); src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg"); font-weight: normal; font-style: normal; }

.slick-next, .slick-prev { position: absolute; display: block; height: 20px; width: 20px; line-height: 0px; font-size: 0px; cursor: pointer; background: transparent; color: transparent; top: 50%; -webkit-transform: translate(0, -50%); -ms-transform: translate(0, -50%); transform: translate(0, -50%); padding: 0; border: none; outline: none; }

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover { outline: none; background: transparent; color: transparent; }

.slick-next:focus:before, .slick-next:hover:before, .slick-prev:focus:before, .slick-prev:hover:before { opacity: 1; }

.slick-next.slick-disabled:before, .slick-prev.slick-disabled:before { opacity: 0.25; }

.slick-next:before, .slick-prev:before { font-family: "slick"; font-size: 20px; line-height: 1; color: white; opacity: 0.75; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-prev { left: -25px; }

[dir=rtl] .slick-prev { left: auto; right: -25px; }

.slick-prev:before { content: "←"; }

[dir=rtl] .slick-prev:before { content: "→"; }

.slick-next { right: -25px; }

[dir=rtl] .slick-next { left: -25px; right: auto; }

.slick-next:before { content: "→"; }

[dir=rtl] .slick-next:before { content: "←"; }

.slick-dotted.slick-slider { margin-bottom: 30px; }

.slick-dots { position: absolute; bottom: -25px; list-style: none; display: block; text-align: center; padding: 0; margin: 0; width: 100%; }

.slick-dots li { position: relative; display: inline-block; height: 20px; width: 20px; margin: 0 5px; padding: 0; cursor: pointer; }

.slick-dots li button { border: 0; background: transparent; display: block; height: 20px; width: 20px; outline: none; line-height: 0px; font-size: 0px; color: transparent; padding: 5px; cursor: pointer; }

.slick-dots li button:focus, .slick-dots li button:hover { outline: none; }

.slick-dots li button:focus:before, .slick-dots li button:hover:before { opacity: 1; }

.slick-dots li button:before { position: absolute; top: 0; left: 0; content: "•"; width: 20px; height: 20px; font-family: "slick"; font-size: 6px; line-height: 20px; text-align: center; color: black; opacity: 0.25; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before { color: black; opacity: 0.75; }

.slick-slider { position: relative; display: block; -webkit-box-sizing: border-box; box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }

.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-list, .slick-slider .slick-track { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; margin-left: auto; margin-right: auto; }

.slick-track:after, .slick-track:before { content: ""; display: table; }

.slick-track:after { clear: both; }

.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }

[dir=rtl] .slick-slide { float: right; }

.slick-slide img { display: block; }

.slick-slide.slick-loading img { display: none; }

.slick-slide.dragging img { pointer-events: none; }

.slick-initialized .slick-slide { display: block; }

.slick-loading .slick-slide { visibility: hidden; }

.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

.slick-arrow.slick-hidden { display: none; }

@media all and (min-width: 769px) { .influencer .purchasebox, .influencer .blockrecomend, .influencer .blockcate { display: none; } }

.blockinfluencer .influencer__list { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; }

.blockinfluencer .influencer__item { flex: 0 0 50%; max-width: 50%; border: 1px solid #e9e9e9; border-bottom: 0; }

@media all and (max-width: 768px) { .blockinfluencer .influencer__item { flex: 0 0 100%; max-width: 100%; }
  .blockinfluencer .influencer__item:last-child { border-bottom: 1px solid #e9e9e9; } }

@media all and (min-width: 769px) { .blockinfluencer .influencer__item:nth-child(2n) { border-left: 0; }
  .blockinfluencer .influencer__item:nth-last-child(2), .blockinfluencer .influencer__item:last-child { border-bottom: 1px solid #e9e9e9; } }

.blockinfluencer .influencer__item a { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: center; align-items: center; flex-wrap: nowrap; padding: 15px; }

@media all and (min-width: 769px) { .blockinfluencer .influencer__item a:hover { opacity: 0.7; }
  .blockinfluencer .influencer__item a:hover img { opacity: 1; } }

.blockinfluencer .influencer__item--img { border: 1px solid #e2e2e2; border-radius: 100%; overflow: hidden; width: 102px; height: 102px; min-width: 102px; margin-right: 12px; }

.blockinfluencer .influencer__item--img img { min-height: 100px; }

.blockinfluencer .influencer__item--info { width: calc(100% - 115px); }

.blockinfluencer .influencer__item--info .name { font-size: 18px; }

@media all and (max-width: 768px) { .blockinfluencer .influencer__item--info .name { font-size: 16px; } }

.blockinfluencer .influencer__item--info .description { text-align: justify; font-size: 12px; max-height: 4.5em; display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; -webkit-line-clamp: 3; line-clamp: 3; }

@media all and (min-width: 769px) { .author .purchasebox { display: none; } }

.author .blockauthor { display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: nowrap; margin-top: 40px; }

@media all and (max-width: 768px) { .author .blockauthor { flex-wrap: wrap; position: relative; margin-top: 0; padding-top: 50px; } }

.author .blockauthor--img { width: 160px; height: 160px; border-radius: 99px; margin-right: 30px; overflow: hidden; }

@media all and (max-width: 768px) { .author .blockauthor--img { max-width: 150px; height: 150px; flex: 0 0 100%; margin: 0 auto; } }

.author .blockauthor--info { width: calc(100% - 190px); }

@media all and (max-width: 768px) { .author .blockauthor--info { flex: 0 0 100%; width: 100%; } }

.author .blockauthor--info .blocksocial { margin-top: 0; margin-bottom: 30px; }

@media all and (max-width: 768px) { .author .blockauthor--info .blocksocial.socialshare { position: absolute; left: 0; top: 0; } }

.author .blockauthor--info .blocksocial.socialauthor { margin-top: 40px; margin-bottom: 0; }

@media all and (max-width: 768px) { .author .blockauthor--info .blocksocial.socialauthor { margin-top: 15px; } }

.author .blockauthor--info .description { text-align: justify; font-size: 16px; }

.author .blockrecomend { margin-bottom: 0; }

@media all and (max-width: 768px) { .author .blockrecomend { margin-top: 40px; } }

.author .blockrecomend .blocktitle { color: #00a2d3; background: #ebf8ff; border-bottom: 0; margin: 0; padding: 16px 20px; }

.author .blockrecomend__list--item { margin: 30px 0; }

@media all and (max-width: 768px) { .author .blockrecomend__list--item { margin: 15px 0; } }

.author .blockrecomend__list--img { max-width: 200px; width: 200px; }

@media all and (max-width: 768px) { .author .blockrecomend__list--img { min-width: 105px; max-width: 105px; } }

.author .blockrecomend__list--text { width: calc(100% - 225px); }

@media all and (max-width: 768px) { .author .blockrecomend__list--text { flex: 1 1 auto; width: 100%; } }

.author .blockrecomend__list--text .title { text-align: justify; font-size: 18px; }

@media all and (max-width: 768px) { .author .blockrecomend__list--text .title { font-size: 15px; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3; line-clamp: 3; overflow: hidden; max-height: 4.5em; font-weight: bold; } }

.author .blockrecomend__list--text .description { text-align: justify; font-size: 16px; margin-top: 10px; }

@media all and (max-width: 768px) { .author .blockrecomend__list--text .description { display: none; } }

.author .textellipsis br { display: none !important; }
