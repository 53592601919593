.Header{
  background: #fff;
  &__cont{
    position: relative;
    @include flexbox(space-between, center);
    height: 95px;
    @include query($q768) {
      height: 60px;
    }
    @include query($q520) {
      height: 45px;
    }
    &--logo{
      position: relative;
      z-index: 1;
      img{
        height: 56px;
      }
      @include query($q768) {
        margin: 0 auto;
        right: -30px;
        img{
          height: 26px;
        }
      }
      @include query($q520) {
        right: -22px;
      }
    }
    &--nav{
      @include minquery(769) {
        flex: 0 0 70%;
      }
    }
  }
}