button{
  border: none;
  outline: none;
  cursor: pointer;
}

.button{
  transition: all 0.3s;
  background: $blue;
  padding: 20px 0;
  a{
    display: block;
    margin: 0 auto;
    border-radius: 99px;
    border: 2px solid #fff;
    font-weight: bold;
    text-align: center;
    padding: 14px 25px;
    background: #ffea00;
    @include arrblue;
  }
}