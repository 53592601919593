@media all and (min-width: 769px) and (max-width: 1080px) {
  html {
    overflow-x: scroll;
  }
}
@media all and (min-width: 769px) and (max-width: 1080px) {
  body {
    min-width: 1080px;
    width: 1080px;
    width: 100%;
    overflow-x: scroll;
  }
}
body {
  @include noto;
  font-size: 14px;
  font-weight: 500;
  color: $cltext;
  @include minquery(769){
    background: $bgbody;
  }
  p,a {
    line-height: normal;
    color: $cltext;
  }
  .img {
    overflow: hidden;
    line-height: 0;
    img {
      width: 100%;
      height: 100%;
      transform: scale(1);
      transition-duration: 4s;
      -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      -o-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      -webkit-transition-property: -webkit-transform;
      -o-transition-property: transform;
      transition-property: transform, -webkit-transform;
    }
  }
}
.container {
  max-width: $container+px;
  margin: 0 auto;
  @include query($container) {
    max-width: 1200px;
  }
  @include query($q768) {
    padding: 0;
    max-width: 100%;
  }
  &.flexwrap {
    .content {
      background: #fff;
      padding: 30px 15px 50px; 
    }
    @include minquery(769) {
      @include flexbox(space-between, flex-start);
      .content {
        width: 74%;
        max-width: 800px;
        background: #fff;
        border-radius: 10px;
        padding: 40px;
      }
      aside {
        width: 245px;
      }
    }
  }
}
.flexbox {
  @include flexbox(space-between, center);
  flex-wrap: wrap;
  &_start {
    justify-content: flex-start;
  }
  &_end {
    justify-content: flex-end;
  }
  &_center {
    justify-content: center;
  }
}