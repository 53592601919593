.influencer{
  .purchasebox,.blockrecomend,.blockcate{
    @include minquery(769) {
      display: none;
    }
  }
}
.blockinfluencer{
  .influencer{
    &__list{
      @include flexbox(space-between, center);
      flex-wrap: wrap;
    }
    &__item{
      flex: 0 0 50%;
      max-width: 50%;
      border: 1px solid #e9e9e9;
      border-bottom: 0;
      @include query($q768){
        flex: 0 0 100%;
        max-width: 100%;
        &:last-child{
          border-bottom: 1px solid #e9e9e9;
        }
      }
      @include minquery(769){
        &:nth-child(2n){
          border-left: 0;
        }
        &:nth-last-child(2),&:last-child{
          border-bottom: 1px solid #e9e9e9;
        }
      }
      a{
        @include flexbox(center, center);
        flex-wrap: nowrap;
        padding: 15px;
        @include hover;
      }
      &--img{
        border: 1px solid #e2e2e2;
        border-radius: 100%;
        overflow: hidden;
        width: 102px;
        height: 102px;
        min-width: 102px;
        margin-right: 12px;
        img{
          min-height: 100px;
        }
      }
      &--info{
        width: calc(100% - 115px);
        .name{
          font-size: 18px;
          @include query($q768){
            font-size: 16px;
          }
        }
        .description{
          text-align: justify;
          font-size: 12px;
          max-height: 4.5em;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 3;
          line-clamp: 3;
        }
      }
    }
  }
}//influencer