/*====================================================================================
■01.MARGIN DEFAULT
====================================================================================*/
// .m00 { margin: 0px !important; }
// .m05 { margin: 5px !important; }
// .m10 { margin: 10px !important; }
// .m15 { margin: 15px !important; }
// .m20 { margin: 20px !important; }
// .m25 { margin: 25px !important; }
// .m30 { margin: 30px !important; }
// .m35 { margin: 35px !important; }
// .m40 { margin: 40px !important; }
// .m45 { margin: 45px !important; }
// .m50 { margin: 50px !important;}
// .m55 { margin: 55px !important;}
// .m60 { margin: 60px !important;}

.mt00 {margin-top: 0 !important;}
// .mt01 {margin-top: 1px !important;}
// .mt02 {margin-top: 2px !important;}
// .mt03 {margin-top: 3px !important;}
// .mt04 {margin-top: 4px !important;}
// .mt05 {margin-top: 5px !important;}
// .mt06 {margin-top: 6px !important;}
// .mt07 {margin-top: 7px !important;}
// .mt08 {margin-top: 8px !important;}
// .mt09 {margin-top: 9px !important;}
// .mt10 {margin-top: 10px !important;}
// .mt15 {margin-top: 15px !important;}
// .mt20 {margin-top: 20px !important;}
// .mt25 {margin-top: 25px !important;}
// .mt30 {margin-top: 30px !important;}
// .mt35 {margin-top: 35px !important;}
// .mt40 {margin-top: 40px !important;}
// .mt45 {margin-top: 45px !important;}
// .mt50 {margin-top: 50px !important;}
// .mt55 {margin-top: 55px !important;}
// .mt60 {margin-top: 60px !important;}
// .mt65 {margin-top: 65px !important;}
// .mt70 {margin-top: 70px !important;}
// .mt75 {margin-top: 75px !important;}
// .mt80 {margin-top: 80px !important;}
// .mt90 {margin-top: 90px !important;}
// .mt100 {margin-top: 100px !important;}

// .mr00 { margin-right: 0px !important; }
// .mr05 { margin-right: 5px !important; }
// .mr10 { margin-right: 10px !important; }
// .mr15 { margin-right: 15px !important; }
// .mr20 { margin-right: 20px !important; }
// .mr25 { margin-right: 25px !important; }
// .mr30 { margin-right: 30px !important; }
// .mr35 { margin-right: 35px !important; }
// .mr40 { margin-right: 40px !important; }
// .mr45 { margin-right: 45px !important; }
// .mr50 { margin-right: 50px !important;}
// .mr55 { margin-right: 55px !important;}
// .mr60 { margin-right: 60px !important;}

// .mb00 { margin-bottom: 0px !important; }
// .mb01 { margin-bottom: 1px !important; }
// .mb02 { margin-bottom: 2px !important; }
// .mb03 { margin-bottom: 3px !important; }
// .mb04 { margin-bottom: 4px !important; }
// .mb05 { margin-bottom: 5px !important; }
// .mb06 { margin-bottom: 6px !important; }
// .mb07 { margin-bottom: 7px !important; }
// .mb08 { margin-bottom: 8px !important; }
// .mb09 { margin-bottom: 9px !important; }
// .mb10 { margin-bottom: 10px !important; }
// .mb15 { margin-bottom: 15px !important; }
// .mb20 { margin-bottom: 20px !important; }
// .mb25 { margin-bottom: 25px !important; }
// .mb30 { margin-bottom: 30px !important; }
// .mb35 { margin-bottom: 35px !important; }
// .mb40 { margin-bottom: 40px !important; }
// .mb45 { margin-bottom: 45px !important; }
// .mb50 { margin-bottom: 50px !important; }
// .mb55 { margin-bottom: 55px !important; }
// .mb60 { margin-bottom: 60px !important;}

// .ml00 { margin-left: 0px !important; }
// .ml05 { margin-left: 5px !important; }
// .ml10 { margin-left: 10px !important; }
// .ml15 { margin-left: 15px !important; }
// .ml20 { margin-left: 20px !important; }
// .ml25 { margin-left: 25px !important; }
// .ml30 { margin-left: 30px !important; }
// .ml35 { margin-left: 35px !important; }
// .ml40 { margin-left: 40px !important; }
// .ml45 { margin-left: 45px !important; }
// .ml50 { margin-left: 50px !important; }
// .ml55 { margin-left: 55px !important; }
// .ml60 { margin-left: 60px !important;}

// /*====================================================================================
// ■PADDING DEFAULT
// ====================================================================================*/
// .p00 { padding: 0px !important; }
// .p01 { padding: 1px !important; }
// .p02 { padding: 2px !important; }
// .p03 { padding: 3px !important; }
// .p04 { padding: 4px !important; }
// .p05 { padding: 5px !important; }
// .p06 { padding: 6px !important; }
// .p07 { padding: 7px !important; }
// .p08 { padding: 8px !important; }
// .p09 { padding: 9px !important; }
// .p10 { padding: 10px !important; }
// .p15 { padding: 15px !important; }
// .p20 { padding: 20px !important; }
// .p25 { padding: 25px !important; }
// .p30 { padding: 30px !important; }
// .p35 { padding: 35px !important; }
// .p40 { padding: 40px !important; }
// .p45 { padding: 45px !important; }
// .p50 { padding: 50px !important;}
// .p55 { padding: 55px !important;}
// .p60 { padding: 60px !important;}

// .pl00 { padding-left: 0px !important; }
// .pl05 { padding-left: 5px !important; }
// .pl10 { padding-left: 10px !important; }
// .pl15 { padding-left: 15px !important; }
// .pl20 { padding-left: 20px !important; }
// .pl25 { padding-left: 25px !important; }
// .pl30 { padding-left: 30px !important; }
// .pl35 { padding-left: 35px !important; }
// .pl40 { padding-left: 40px !important; }
// .pl45 { padding-left: 45px !important; }
// .pl50 { padding-left: 50px !important;}
// .pl55 { padding-left: 55px !important;}
// .pl60 { padding-left: 60px !important;}

// .pr00 { padding-right: 0px !important; }
// .pr05 { padding-right: 5px !important; }
// .pr10 { padding-right: 10px !important; }
// .pr15 { padding-right: 15px !important; }
// .pr20 { padding-right: 20px !important; }
// .pr25 { padding-right: 25px !important; }
// .pr30 { padding-right: 30px !important; }
// .pr35 { padding-right: 35px !important; }
// .pr40 { padding-right: 40px !important; }
// .pr45 { padding-right: 45px !important; }
// .pr50 { padding-right: 50px !important;}
// .pr55 { padding-right: 55px !important;}
// .pr60 { padding-right: 60px !important;}

// .pb00 { padding-bottom: 0px !important; }
// .pb05 { padding-bottom: 5px !important; }
// .pb10 { padding-bottom: 10px !important; }
// .pb15 { padding-bottom: 15px !important; }
// .pb20 { padding-bottom: 20px !important; }
// .pb25 { padding-bottom: 25px !important; }
// .pb30 { padding-bottom: 30px !important; }
// .pb35 { padding-bottom: 35px !important; }
// .pb40 { padding-bottom: 40px !important; }
// .pb45 { padding-bottom: 45px !important; }
// .pb50 { padding-bottom: 50px !important;}
// .pb55 { padding-bottom: 55px !important;}
// .pb60 { padding-bottom: 60px !important;}

// .pt00 { padding-top: 0px !important; }
// .pt05 { padding-top: 5px !important; }
// .pt10 { padding-top: 10px !important; }
// .pt15 { padding-top: 15px !important; }
// .pt20 { padding-top: 20px !important; }
// .pt25 { padding-top: 25px !important; }
// .pt30 { padding-top: 30px !important; }
// .pt35 { padding-top: 35px !important; }
// .pt40 { padding-top: 40px !important; }
// .pt45 { padding-top: 45px !important; }
// .pt50 { padding-top: 50px !important;}
// .pt55 { padding-top: 55px !important;}
// .pt60 { padding-top: 60px !important;}

/*====================================================================================
■WIDTH DEFAULT
====================================================================================*/
// .w5 {width: 5% !important;}
// .w10 {width: 10% !important;}
// .w15 {width: 15% !important;}
// .w20 {width: 20% !important;}
// .w25 {width: 25% !important;}
// .w30 {width: 30% !important;}
// .w35 {width: 35% !important;}
// .w40 {width: 40% !important;}
// .w45 {width: 45% !important;}
// .w49 {width: 49% !important;}
// .w50 {width: 50% !important;}
// .w55 {width: 55% !important;}
// .w59 {width: 59% !important;}
// .w60 {width: 60% !important;}
// .w65 {width: 65% !important;}
// .w70 {width: 70% !important;}
// .w80 {width: 80% !important;}
// .w90 {width: 90% !important;}
// .w100 {width: 100% !important;}

/*====================================================================================
■MARGIN% DEFAULT
====================================================================================*/
// .ml1per{margin-left: 1%;}
// .ml2per{margin-left: 2%;}
// .ml3per{margin-left: 3%;}
// .ml4per{margin-left: 4%;}
// .mr1per{margin-right: 1%;}
// .mr2per{margin-right: 2%;}
// .mr3per{margin-right: 3%;}
// .mr4per{margin-right: 4%;}
// .mt1per{margin-top: 1%;}
// .mt2per{margin-top: 2%;}
// .mt3per{margin-top: 3%;}
// .mt4per{margin-top: 4%;}
// .mt5per{margin-top: 5%;}
// .mt6per{margin-top: 6%;}
// .mt7per{margin-top: 7%;}
// .mt8per{margin-top: 8%;}
// .mt9per{margin-top: 9%;}
// .mt10per{margin-top: 10%;}
// .pd1per{padding: 1%;}
// .pd2per{padding: 2%;}
// .pd3per{padding: 3%;}
// .pd4per{padding: 4%;}

.pull_left{ float: left;}
.pull_right{ float: right;}
.text_left { text-align: left !important;}
.text_right { text-align: right !important;}
.text_center { text-align:center !important;}
.text_justify { text-align:justify !important;}
.text_nowrap { white-space:nowrap !important;}
.text_lower { text-transform:lowercase!important;}
.text_upper { text-transform:uppercase!important;}
.text_capitalize { text-transform:capitalize !important;}
.text_normal { font-weight:normal !important;}
.text_bold { font-weight:bold !important;}
.text_line { text-decoration: underline;}
.text_noline { text-decoration: none !important;}
.text_over {overflow: hidden !important;}

// .fz10 {font-size: 10px !important;}
// .fz11 {font-size: 11px !important;}
// .fz12 {font-size: 12px !important;}
// .fz13 {font-size: 13px !important;}
// .fz14 {font-size: 14px !important;}
// .fz15 {font-size: 15px !important;}
// .fz16 {font-size: 16px !important;}
// .fz17 {font-size: 17px !important;}
// .fz18 {font-size: 18px !important;}
// .fz19 {font-size: 19px !important;}
// .fz20 {font-size: 20px !important;}
// .fz22 {font-size: 22px !important;}
// .fz24 {font-size: 24px !important;}

// .lhnm { line-height:normal!important;}
.lh00 { line-height:0 !important;}
// .lh12 { line-height:1.2 !important;}
// .lh13 { line-height:1.3 !important;}
// .lh14 { line-height:1.4 !important;}
// .lh15 { line-height:1.5 !important;}
// .lh16 { line-height:1.6 !important;}
// .lh17 { line-height:1.7 !important;}
// .lh18 { line-height:1.8 !important;}
// .lh20 { line-height:2 !important;}
// .lh23 { line-height:2.3 !important;}

.clearfix:before, .clearfix:after{content:"\0020"; display:block; height:0; overflow:hidden}
.clearfix:after{clear:both}
.clearfix{zoom:1}    

.visible{
    visibility: visible;
}
.visible-hidden{
    visibility: hidden;
}
.dispc,.breakpc {
    display: block;
    @include query(768) {
        display: none;
    }
}
.dissp,.breaksp {
    display: block;
    @include minquery(769) {
        display: none;
    }
}
.flexFont{
    font-size: 100%;
}
.obj__fit, .imgfit {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    font-family: 'object-fit: cover;';
}
.overflow{
    overflow: hidden;
}
//END OTHER