.blockhead {
  @include query(520) {
    @include flexbox(flex-start, flex-start);
    flex-wrap: wrap;
  }
  &--title {
    font-size: 28px;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 30px;
    text-align: justify;
    width: 100%;
    border-left: 10px solid $blue;
    padding: 2px 0 6px;
    padding-left: 20px;
    @include query($q768) {
      font-size: 20px;
      border-left: 5px solid $blue;
      padding-left: 15px;
      margin-bottom: 15px;
    }
  }
  .blocksocial{
    margin: 30px 0;
    @include query($q768) {
      margin-top: 0;
      margin-bottom: 15px;
    }
  }
}//blockhead

.blocktitle {
  border-left: 5px solid $bgblue;
  padding-bottom: 10px;
  @include minquery(769) {
    border-left: 2x solid $bgblue;
  }
  &--listtitle {
    @include fonttitle;
    border-left: 0;
    border-bottom: 5px solid $bgblue;
    margin-bottom: 20px;
  }
}//blocktitle

.blockrecomend{
  margin: 50px 0;
  @include query($q768){
    margin: 40px 0 35px;
  }
  &__list{
    &--item{
      margin: 20px 0;
      @include query($q768) {
        margin-bottom: 30px;
        &:last-child{
          margin-bottom: 0;
        }
      }
      a{
        @include flexbox(flex-start, flex-start);
        flex-wrap: nowrap;
        @include hover;
      }
    }
    &--img{
      max-width: 140px;
      width: 140px;
      @include query($q768) {
        min-width: 100px;
        max-width: 100px;
      }
    }
    &--text{
      width: calc(100% - 160px);
      font-weight: bold;
      margin-left: 20px;
      text-align: justify;
      font-size: 18px;
      @include query($q768) {
        width: calc(100% - 120px);
        font-size: 16px;
        max-height: 6em;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        width: 100%;
        -webkit-line-clamp: 4;
        line-clamp: 4;
      }
    }
  }
}//blockrecomend

.blockcate{
  &__list{
    @include flexbox(space-between, center);
    flex-wrap: wrap;
    &--item{
      flex: 0 0 25%;
      max-width: 170px;
      margin-bottom: 15px;
      @include query($q768) {
        flex: 0 0 48%;
        margin-bottom: 10px;
        max-width: inherit;
      }
      a{
        @include flexbox(flex-start, center);
        border: 2px solid $bgblue;
        border-radius: 10px;
        overflow: hidden;
        @include hover;
      }
    }
    &--img{
      width: 50px;
    }
    &--text{
      font-size: 14px;
      color: $bgblue;
      width: calc(100% - 60px);
      margin-left: 10px;
    }
  }
}//blockcate

.bnrareabottom{
  padding: 0 5px;
  margin: 50px 0 0;
  .Footer__bnr--area:last-child{
    margin-bottom: 0;
  }
}//bnrareabottom

.purchasebox{
  background: $blue;
  padding: 20px 10px 30px;
  margin: 50px 0;
  @include query($q768){
    padding: 20px 25px;
    margin: 40px 0 35px;
  }
  &--title{
    color: #fff;
    font-weight: bold; 
    font-size: 24px;
    text-align: center;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    line-height: 1;
    @include query($q768){
      display: none;
    }
  }
  &--subtitle{
    margin: 25px auto 30px;
    @include query($q768){
      margin-top: 0;
      margin-bottom: 15px;
    }
    img{
      margin: 0 auto;
    }
  }
  &--search{
    @include flexbox(space-between, center);
    margin: 15px 30px;
    @include query($q768){
      flex-wrap: wrap;
      margin: 15px 0;
    }
    .button{
      padding: 0;
    }
    .search{
      &--select{
        @include arrblue;
        &::after{
          right: 19px;
          transform: rotate(90deg);
        }
        @include minquery(769){
          min-width: 345px;
          width: 345px;
        }
        @include query($q768){
          flex: 0 0 100%;
          margin-bottom: 15px;
        }
        select{
          background: #fff;
          border: 2px solid #ffea00;
          font-size: 18px;
          width: 100%;
          height: 70px;
          padding: 0 30px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          @include query($q768){
            font-size: 12px;
            height: 50px;
            padding: 0 15px;
          }
        }
      }
      &--button{
        @include minquery(769){
          min-width: 280px;
          width: 280px;
        }
        @include query($q768){
          flex: 0 0 100%;
        }
        a{
          font-size: 18px;
          padding: 20px 25px;
        }
        @include query($q768){
          a{
            font-size: 15px;
            padding: 12px 25px;
          }
        }
      }
    }
  }
  &--icon{
    @include flexbox(center, center);
    li{
      max-width: 102px;
      margin: 0 10px;
      width: 102px;
      @include query($q768){
        margin: 0 5px;
      }
    }
  }
}//purchasebox

.blocksocial{
  margin: 50px 0;
  width: 100%;
  @include query($q768){
    margin: 35px 0;
  }
  ul{
    @include flexbox(flex-start, flex-start);
    width: 100%;
  }
  li{
    margin-right: 20px;
    text-align: center;
    @include query($q768){
      margin-right: 15px;
    }
    img{
      margin: 0 auto;
    }
  }
  &.socialshare{
    li{
      width: 35px;
      height: 35px;
      @include query($q768){
        width: 25px;
        height: 25px;
      }
      img{
        max-height: 35px;
        @include query($q768){
          max-height: 25px;
        }
      }
    }
  }
  &.socialauthor{
    ul{
      justify-content: flex-end;
    }
    li{
      width: 25px;
      height: 25px;
      @include query($q768) {
        width: 20px;
        height: 20px;
      }
      &:last-child{
        margin-right: 0;
      }
      img{
        max-height: 25px;
        @include query($q768) {
          max-height: 20px;
        }
      }
    }
  }
}//blocksocial

.block-profile{
  margin: 50px 0;
  @include query($q768){
    margin: 40px 0 35px;
  }
  &--title{
    font-size: 21px;
    font-weight: bold;
    border-bottom: 5px solid $blue;
    color: #434343;
    padding-bottom: 12px;
    line-height: 1;
    @include query($q768) {
      font-size: 15px;
    }
  }
  &--list{
    margin: 30px 0;
    @include query($q768) {
      margin: 20px 0 35px;
    }
  }
  &--item{
    @include flexbox(flex-start, flex-start);
    .imgbox{
      width: 130px;
      height: 130px;
      min-width: 130px;
      overflow: hidden;
      border-radius: 99px;
      margin-right: 30px;
      @include query($q768) {
        width: 82px;
        height: 82px;
        min-width: 82px;
        margin-right: 15px;
      }
    }
    .txtwarp{
      .namearea{
        @include flexbox(flex-start, flex-start);
        margin-bottom: 20px; 
        @include query($q768) {
          flex-wrap: wrap;
          margin-bottom: 15px;
        }
        .name{
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 0;
          line-height: 1;
          margin-right: 30px;
          @include query($q768) {
            font-size: 14px;
            margin-right: 15px;
            margin-bottom: 10px;
          }
        }
        .socialauthor{
          @include flexbox(flex-start, center);
          margin: 0;
          @include query($q768) {
            margin-left: 10px;
          }
          li{
            width: 18px;
            height: 18px;
            max-height: 18px;
            text-align: center;
            margin: 0;
            margin-right: 12px;
            @include query($q768) {
              width: 16px;
              height: 16px;
            }
            img{
              max-height: 18px;
              @include query($q768) {
                max-height: 16px;
              }
            }
          }
        }
        .belongs{
          color: #a3a3a3;
          font-weight: bold;
          font-size: 12px;
          @include query($q768) {
            margin-bottom: 10px;
          }
        }
        a.link{
          color: #0000ee;
          text-decoration: underline;
          font-weight: bold;
          font-size: 12px;
          margin: 0;
          margin-left: 20px;
          @include query($q768) {
            font-size: 13px;
            display: block;
            flex: 0 0 100%;
            text-align: left;
            margin-left: 0;
          }
          &:hover{
            text-decoration: none;
            opacity: 0.7;
          }
        }
      }
      .txtarea{
        text-align: justify;
      }
      .link{
        margin-top: 20px;
        text-align: right;
        @include query($q768) {
          margin-top: 10px;
        }
        a{
          font-size: 14px;
          color: $link;
          text-decoration: underline;
          @include hover;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }
  &--boxboder{
    background: $bgbody;
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 25px 20px;
    padding-right: 35px;
    @include query($q768) {
      padding-right: 20px;
    }
    .block-profile--list{
      margin: 0;
      margin-bottom: 30px;
      @include query($q768) {
        margin-bottom: 20px;
      }
    }
    .block-profile--item{
      .imgbox{
        width: 120px;
        height: 120px;
        min-width: 120px;
        @include query($q768) {
          width: 90px;
          height: 90px;
          min-width: 90px;
        }
      }
    }
  }
}//block-profile