.Footer{
	background: #fff;
	.FooterNav{
		@include flexbox(center, center);
		flex-wrap: wrap;
		margin-top: 22px;
		li{
			margin: 0 13px;
			list-style: none;
			@include query($q768) {
				margin: 0;
				margin-right: 15px;
				&:nth-child(3),&:last-child{
					margin-right: 0;
				}
				&:nth-child(3){
					page-break-after: always;
					margin-right: 0;
				}
			}
		}
		a{
			color: $link;
			font-size: 14px;
			&:last-child{
				margin-right: 0;
			}
			@include query($q768) {
				padding: 7px 0;
				margin-right: 20px;
				font-size: 12px;
				display: block;
			}
			@include minquery(769) {
				margin-right: 25px;
				position: relative;
				padding: 5px 0;
				&::after {
					position: absolute;
					content: "";
    			transition: all 0.35s ease;
					bottom: 1px;
					display: block;
					height: 1px;
					width: 0%;
					background-color: $link;
					left: 0;
				}
				&:hover{
					&::after {
						opacity: 1;
						width: 100%;
					}
				}
			}
		}
	}
	.ShareNav{
		@include flexbox(center, center);
		a{
			@include flexbox(center, center);
			margin-right: 15px;
			img{
				width: 37px;
				height: 37px;
				@include query($q768) {
					width: 25px;
					height: 25px;
				}
			}
			&:last-child{
				margin-right: 0;
			}
			@include minquery(769) {
				margin-right: 20px;
				&:hover{
					opacity: 0.7;
				}
			}
		}
	}
	.copyright {
		background: $bgblue;
		height: 70px;
		margin-top: 33px;
		@include query($q768) {
			margin-top: 15px;
			height: 50px;
		}
		p{
			font-size: 11px;
			text-align: center;
			color: #fff;
			line-height: 70px;
			letter-spacing: 1px;
			@include query($q768) {
				line-height: 50px;
			}
		}
	}
	.page_top_cont {
		width:100%;
		max-width: 100%;
		position:fixed; 
		z-index:990;
		bottom: 15px;
		right: 0;
		.page_top {
			.to_top {
				cursor:pointer;
				display:block;
				position:absolute; 
				bottom:0; 
				right:15px; 
				zoom: 1;
				text-align: right;
				z-index: 999;
				img{
					width: 45px;
					height: 45px;
				}
			}
		}
	}
	&__common{
		margin-top: 60px;
		@include query($q768) {
			margin-top: 40px;
		}
	}
	&__about{
		background: url(../img/common/background_sec_pc.jpg) no-repeat center center;
		background-size: cover;
		height: 442px;
		@include query($q768) {
			background: url(../img/common/background_sec_sp.jpg) no-repeat center center;
			padding: 40px;
			height: auto;
		}
		.flexbox{
			@include minquery(769) {
				flex-wrap: nowrap;
				min-height: 442px;
				height: 442px;
			}
		}
		&--img{
			@include query($q768) {
				flex: 0 0 100%;
				text-align: center;
				margin-bottom: 24px;
				img{
					max-width: 165px;
					margin: 0 auto;
				}
			}
			@include minquery(769) {
				flex: 0 0 327px;
				max-width: 32%;
				margin-top: -44px;
			}
		}
		&--desc{
			p{
				font-size: 18px;
				font-weight: 700;
				margin: 26px 0;
				text-align: justify;
				color: #000;
				span{
					color: $bgblue;
					font-weight: 700;
				}
				&:first-child{
					margin-top: 0;
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
			@include query($q768) {
				flex: 0 0 100%;
				p{
					font-size: 12px;
				}
			}
			@include minquery(769) {
				width: calc(100% - 380px);
				max-width: 68%;
			}
		}
	}
	&__bnr{
		padding: 60px 0;
		background: #fff;
		@include query($q768) {
			padding: 40px 20px;
		}
		&--area{
			flex: 0 0 48.5%;
			@include query($q768) {
				flex: 0 0 100%;
				margin-bottom: 15px;
			}
			img{
				width: 100%;
			}
			&:nth-child(3){
				flex: 0 0 100%;
				margin-top: 30px;
				@include query($q768) {
					margin: 0;
				}
			}
		}
	}
}