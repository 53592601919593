.single{
  .post-thumbnail {
    margin: 30px 0;
    @include query($q768) {
      margin: 0;
      margin-bottom: 15px;
    }
  }
}//single
.single__detail {
  @include minquery(769) {
    margin-top: 30px;
  }
  p,dl,dt,dd,td,th,div,li,blockquote {
    @include fontpage;
  }
  h2,h3,h4,h5,h6{
    font-weight: bold;
    span{
      font-weight: bold;
    }
  }
  h2 {
    font-size: 26px;
    margin: 65px 0 40px;
    border-left: 10px solid $blue;
    padding-left: 20px;
    @include query($q768) {
      font-size: 20px;
      border-left: 5px solid $blue;
      padding-left: 15px;
      margin-top: 50px;
      margin-bottom: 40px;
    }
  }
  h3{
    background: $bgbody;
    padding: 16px 20px;
    font-size: 21px;
    margin: 50px 0 30px;
    @include query($q768) {
      font-size: 17px;
      padding: 10px 20px;
      margin: 45px 0 25px;
    }
  }
  h4{
    color: $blue;
    font-size: 19px;
    margin: 50px 0 30px;
    @include query($q768) {
      font-size: 17px;
      margin: 45px 0 25px;
    }
  }
  h5,h6{
    font-size: 17px;
    margin-bottom: 10px;
  }
  [data-rank]{
    border-left: 10px solid $blue;
    margin-bottom: 12px;
    background-color: #f0f5ff;
    padding: 16px 16px 14.5px 55px;
    box-sizing: border-box;
    min-height: 58px;
    color: #434343;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: center left 15px;
    position: relative;
    margin-top: 0;
    @include query($q768) {
      font-size: 17px;
      margin-bottom: 15px;
    }
  }
  @for $i from 1 through 11 {
    [data-rank="#{$i}"] {
      background-image: url(../img/common/icon/icon-no#{$i}.png);
    }
  } 
  div, p {
    text-align: justify;
  }
  p{
    margin-bottom: 20px;
    @include query($q768) {
      margin-bottom: 15px;
    }
    &.mt30{
      margin-top: 30px;
    }
  }
  ul,
  ol {
    margin: 30px 0;
    li {
      position: relative;
      list-style: none;
      margin-bottom: 10px;
      @include query($q768) {
        margin-bottom: 15px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  ul {
    &.liststyle{
      &--num{
        counter-reset: item;
        li {
          &::before{
            content: counter(item);
            counter-increment: item;
            color: $blue;
            margin-right: 10px;
            margin-left: 5px;
          }
        }
      }
      &--check{
        li{
          padding-left: 33px;  
          &::before{
            position: absolute;
            content: '';
            background: url(../img/common/icon/icon-check.png) no-repeat left center;
            background-size: 20px;
            width: 20px;
            height: 20px;
            left: 0;
            top: 50%;
            margin-top: -10px;
          }
        }
      }
      &--dot{
        li{
          &::before{
            content: "・";
            margin-right: 5px;
          }
        }
      }
      &--arrow{
        li{
          @include arrblue;
          padding-left: 20px;
          &::after{
            right: inherit;
            left: 5px;
          }
        }
      }
    }
  }
  table {
    width: 100%;
    margin-bottom: 30px;
    table-layout: fixed;
    @include query($q768) {
      margin-top: 35px;
    }
    tr:first-child{
      th,td{
        font-weight: bold;
        @include query($q768) {
          padding: 7px 10px;
        }
      }
    }
    th,
    td {
      vertical-align: middle;
      text-align: center;
      padding: 16px 15px;
      @include border;
      font-size: 15px;
      &:first-child{
        font-weight: bold;
        background: #f2f2f2;
        width: 55%;
        @include query($q768) {
          width: 45%;
        }
      }
      @include query($q768) {
        padding: 15px 10px;
      }
    }
    &.is-style-org{
      tr:first-child{
        td{
          background: #ff8f3c;
          color: #fff;
        }
      }
    }
    &.is-style-blue{
      tr:first-child{
        td{
          background: #7acae9;
          color: #fff;
        }
      }
    }
    &.is-style-normal{
      th,td{
        &:first-child{
          background: #eaeaea;
          @include query($q768) {
            padding: 15px 10px;
          }
        }
      }
      tr:nth-child(odd){
        background: #f8f8f8;
      }
    }
  }
  blockquote {
    background: #f7f7f7;
    padding: 40px;
    margin-top: 50px;
    text-align: justify;
    position: relative;
    @include query($q768) {
      padding: 30px 25px;
    }
    &::before,&::after{
      position: absolute;
      content: '';
      background: url(../img/common/icon/icon-quotes.png) no-repeat;
      background-size: 100%;
      width: 33px;
      height: 25px;
    }
    &::before{
      left: 20px;
      top: -12px;
      @include query($q768) {
        left: 15px;
      }
    }
    &::after{
      right: 20px;
      bottom: -12px;
      transform: rotate(180deg);
      @include query($q768) {
        right: 15px;
      }
    }
  }
  a {
    text-decoration: underline;
    color: $link;
    &:hover{
      text-decoration: none;
    }
    span{
      color: $link;
    }
  }
  img.lazy-loaded{
    background-size: 180px !important;
  }
  img.noloadbg{
    background: none !important;
  }
  .btnarea{
    margin: 50px 0;
    @include query($q768) {
      margin: 45px 0;
    }
  }
  .btndetail{
    overflow: hidden;
    border-radius: 99px;
    margin: 20px 0;
    a{
      font-weight: bold;
      display: block;
      text-align: center;
      @include arrright($cl: #fff, $size:7px);
      color: #fff;
      border-radius: 99px;
      text-decoration: none;
      padding: 18px 50px; 
      span{
        font-weight: bold;
        color: #fff;
      }
      &::before{
        right: 30px;
      }
      @include minquery(769) {
        &:hover{
          opacity: 0.7;
        }
      }
    }
    &--green{
      background: #53c267;
    }
    &--blue{
      background: #1bc0c7;
    }
    &--white{
      background: #fff;
      border: 2px solid #cdcdcd;
      a{
        color: $blue;
        &::before{
          border-top: 3px solid #cdcdcd;
          border-right: 3px solid #cdcdcd;
        }
      }
    }
  }//btndetail

  .listpage{
    background: $bgbody;
    padding: 25px 20px;
    margin: 50px 0;
    .openlist{
      position: relative;
      height: 220px;
      transition: max-height .25s;
      overflow: hidden;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 50%;
        background: -moz-linear-gradient(bottom, $bgbody, rgba(228, 243, 255, 0));
        background: -webkit-linear-gradient(bottom, $bgbody, rgba(228, 243, 255, 0));
        background: linear-gradient(to top, $bgbody, rgba(228, 243, 255, 0));
      }
      &.active {
        overflow: visible;
        &::after{
          background: none;
        }
      }
    }
    @include query($q768) {
      padding: 20px;
      margin: 50px 0 35px;
    }
    &__title{
      line-height: 1;
      @include query($q768) {
        font-size: 17px;
      }
      a{
        font-weight: bold;
        text-decoration: none;
        line-height: 1;
        color: $blue;
      }
    }
    &__list{
      margin-top: 20px;
      margin-bottom: 0;
      @include query($q768) {
        margin-top: 10px;
      }
      a{
        font-size: 14px;
        line-height: 1;
        position: relative;
        padding-left: 12px;
        @include hover;
        &::before{
          position: absolute;
          content: '';
          background: url(../img/common/icon/icon-list.png) no-repeat left center;
          background-size: 10px;
          width: 10px;
          height: 10px;
          left: 0;
          top: 8px;
        }
      }
      li{
        margin-bottom: 0;
        margin-left: 20px;
        @include query($q768) {
          margin-bottom: 3px;
        }
        &:first-child{
          @include arrleft($cl: $lightblue, $size:9px);
          margin-left: 8px;
          &::before{
            left: -10px;
          }
          a:before{
            background: none;
            z-index: -1;
          }
        }
      }
    }
  }//listpage

  .block{
    &-text{
      padding: 30px 25px;
      border-radius: 10px;
      margin-bottom: 30px;
      text-align: justify;
      @include query($q768) {
        padding: 25px;
      }
      ul,ol{
        margin: 0;
      }
    }
    &-blue{
      border: 1px solid $blue;
      background: $bgbody;
    }
    &-pink{
      border: 1px solid #ff7979;
      background: #fff5f5;
    }
    &-gray{
      border: 1px solid #ccc;
      background: #f7f7f7;
    }
    &-white{
      border: 1px solid #ccc;
      background: #fff;
    }
    &-point{
      background: #fffae8;
      border: 1px solid #e1af00;
      border-radius: 0;
      padding: 25px;
      position: relative;
      margin-top: 80px;
      @include query($q768) {
        margin-top: 60px;
        padding: 20px;
      }
      &::before{
        position: absolute;
        content: '';
        background: url(../img/common/txt-point.png) no-repeat;
        background-size: 100%;
        width: 72px;
        height: 27px;
        left: -1px;
        top: -28px;
      }
    }//block-point
    &-link{
      background: $bgbody;
      padding: 30px 40px;
      margin-top: 50px;
      border-radius: 0;
      @include query($q768) {
        padding: 30px;
      }
      p{
        margin-bottom: 0;
      }
      a{
        display: inline-block;
      }
    }//block-link
    &-reviews{
      border: 1px solid #ccc;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 30px;
      &--tit{
        background: #f7f7f7;
        padding: 15px;
        @include flexbox(flex-start, center);
        @include query($q768) {
          padding: 10px;
        }
        .imgicon{
          width: 55px;
          height: 55px;
          overflow: hidden;
          border-radius: 99px;
          margin-right: 20px;
        }
        .name{
          font-weight: bold;
        }
      }
      &--info{
        padding: 20px;
        @include query($q768) {
          padding: 20px 15px;
        }
        .tit{
          font-weight: bold;
          margin-bottom: 10px;
          @include minquery(769) {
            font-size: 18px;
          }
        }
        .txt{
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }//block-reviews
  }

  .block-voicetalk {
    margin: 50px 0;
    padding: 0 15px;
    @include query($q768) {
      margin: 35px 0;
      padding: 0;
    }
    .voicetalkarea {
      &__item {
        margin-bottom: 30px;
        @include flexbox(flex-start, flex-start);
        @include query($q768) {
          margin-bottom: 20px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &__img {
        width: 100px;
        height: 100px;
        min-width: 100px;
        position: relative;
        @include query($q768) {
          flex: 0 0 60px;
          height: 60px;
          min-width: 60px;
        }
        img {
          overflow: hidden;
          @include bradius(99px);
        }
      }
      &__txt {
        @include bradius(8px);
        position: relative;
        padding: 15px;
        background: #fff;
        border: 1px solid #ccc;
        @include query($q768) {
          padding: 10px;
          width: calc(100% - 70px);
        }
        &::after {
          position: absolute;
          content: '';
          width: 14px;
          height: 26px;
          top: 40px;
          z-index: 1;
          @include query($q768) {
            top: 18px;
          }
        }
        p {
          margin-bottom: 0;
        }
      }
      &__left {
        &__item {
          margin-left: 20px;
          &::after {
            background: url(../img/common/icon/icon-arr.png) no-repeat;
            background-size: 14px;
            left: -14px;
          }
        }
      }
      &__right {
        flex-direction: row-reverse;
        &__item {
          margin-right: 20px;
          &::after {
            background: url(../img/common/icon/icon-arr.png) no-repeat;
            background-size: 14px;
            right: -14px;
            transform: rotate(180deg);
          }
        }
      }
    }
  }//block-voicetalk
  
}//single__detail

.highlighted {
  background: linear-gradient(0deg, #FFECB9 50%, transparent 50%);
  font-weight: bold !important;
}
.redcolor, .has-red-color{
  font-weight: bold !important;
  color: #f50930 !important;
  span{
    font-weight: bold !important;
    color: #f50930 !important;
  }
}
.bluecolor, .has-blue-color{
  font-weight: bold !important;
  color: #4072d7 !important;
  span{
    font-weight: bold !important;
    color: #4072d7 !important;
  }
}

img.lazyload{
  // background: url(../img/common/logo/logo.gif) no-repeat center center;
  // background-size: 80%;
  // background-position: 50% 50%;
  // min-width: 100%;
  // @include query($q768) {
  //   background-size: 100%;
  // }
}

.btnmore{
  .iconbox{
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0 auto;
    span{
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      margin: auto;
      background-color: #434343;
      transition: all .4s;
      &:nth-of-type(1){
        top: 2.5vw;
        transform: rotate(0deg);
      }
      &:nth-of-type(2){
        top: 2.5vw;
        transform: rotate(90deg);
      }
    }
  }
  .txt{
    text-align: center;
    font-size: 15px;
    margin: 10px 0 0;
    letter-spacing: 0.05em;
  }
}

#toc_container{
  background: #f7f7f7 !important;
  padding: 20px 25px;
  margin: 50px 0;
  border: none !important;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  @include query($q768) {
    padding: 15px;
    margin: 50px 0;
  }
  p.toc_title{
    text-align: center;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 18px;
    position: relative;
    &::before,&::after{
      content: '-';
      margin: 0 5px;
    }
    @include query($q768) {
      font-size: 15px;
    }
  }
  .toc_list{
    margin: 0;
    ul{
      margin: 0;
    }
    li{
      list-style: none;
      margin-bottom: 10px;
      @include query($q768) {
        font-size: 15px;
        margin-bottom: 5px;
      }
      &:before{
        content: '・' !important;
      }
      a{
        text-decoration: underline;
        color: #4072d7;
        font-weight: 500;
        &:hover{
          color: #f50930;
        }
      }
      ul{
        li{
          font-size: 14px;
          a{
            font-weight: normal;
          }
        }
      }
      &:last-child{
        a:after{
          content:'' !important;
        }
      }
    }
    > li:nth-last-child(-n+3) > a:after{
      content:'New!';
      display:inline-block;
      color:red;
      margin-left:5px;
      font-weight: normal;
    }
  }
}