@charset "utf-8";

$cl: #333;
$cltext: #434343;
$cltitle: #434343;
$clgray: #7C7C7C;
$bgbox: #EDEDED;
$bgbody: #ebf8ff;
$lightblue: #7acae9;
$link: #4072d7;
$bgblue: #02a3d4;
$boder: #ccc;

//Container Define
$container: 1080;
$container-large: 1240;
$q960: 960;
$q768: 768; 
$q520: 520;
$q414: 414;
$q320: 320;

//Preload
$preload: true; 

//image dir define.
$dir: "../img/";
$dir2: "../../img/";

//color define:
$white: #fff;
$black: #000;
$blue: #00a2d3 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ff4200 !default;
$orange: #ff8f3c !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;