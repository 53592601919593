//since query3 will use mobile
$querymobile: 768;
//hamburger
.hamburger {
	cursor: pointer;
	position: relative;
	top: 0;
	z-index: 1000;
	height: 60px;
	width: 60px;
	transition: all .3s;
	@include flexbox(center, center);
	@include minquery(769) {
		display: none !important;
	}
	@include query($q520) {
		width: 44px;
		height: 44px;
		background: none;
	}
	&__inside {
		z-index: 99;
		position: relative;
		height: 38%;
		width: 56%;
		span {
			height: 2px;
			position: absolute;
			background: #000;
			text-shadow: 0 0 0 #000;
			display: inline-block;
			width: 100%;
			@include transition(.4s);
			&:first-child {
				top: 0;
			}
			&:nth-child(2) {
				@include centerver;
			}
			&:last-child {
				bottom: 0;
			}
			@include query($q520) {
				background: $cl;
			}
		}
	}
}//hamburger

//GloNav
.GloNav {
	position: relative;
	z-index: 999;
	width: 100%;
	@include query($querymobile) {
		position: absolute;
		left: 0;
		top: 61px;
	}
	@include query($q520) {
		top: 45px;
	}
	&__inside {
		width: 100%;
		z-index: 99;
		@include query($querymobile) {
			position: absolute;
			left: -100%;
			background: rgba($white, 0.95);
			transition: all .3s ease;
			opacity: 0;
			top: 2px;
		}
		&--list {
			@include flexbox(space-between, center);
			@include query($querymobile) {
				display: block;
				height: auto;
				flex-wrap: wrap;
			}
			li {
				position: relative;
				list-style: none;
				a {
					font-size: 15px;
					color: $cl;
					text-decoration: none;
					padding: 15px 0;
					text-align: center;
					@include hover;
					span {
						font-weight: bold;
					}
					@include minquery(769) {
						font-size: 13px;
						@include flexbox(center, center);
						flex-wrap: wrap;
						padding: 0;
						img {
							width: 30px;
							height: 30px;
						}
						span{
							display: block;
							flex: 0 0 100%;
							font-size: 14px;
							margin-top: 7px;
						}
					}
					@include query($querymobile) {
						@include flexbox(flex-start, center);
						padding: 20px 15px;
						text-align: left;
						img {
							margin-right: 15px;
							width: 25px;
							height: 25px;
						}
					}
				}
				@include minquery(769) {
					flex: 0 0 120px;
					&:last-child{
						flex: 0 0 50px;
					}
					&:hover {
						a {
							text-decoration: underline;
						}
					}
				}
				@include query($querymobile) {
					border-bottom: 1px solid #ccc;
					flex: 0 0 100%;
				}
			}
		}
	}
}//GloNav

//EFFECT
.GloNav--open {
	.GloNav__inside{
		left: 0;
		opacity: 1;
		&--list {
			> li {
				@include fadeInUp($duration: 0.3s,$function: cubic-bezier(0, 0.2, 0.8, 1), $delay: 0s);
				@include query($querymobile) {
					@include fadeInLeft($duration: 0.6s,$function: cubic-bezier(0, 0.2, 0.8, 1), $delay: 0s);
				}
			}
			> li {
				@for $i from 1 through 7 {
					&:nth-child(#{$i}) {
						animation-delay: $i * .1 + s;
					}
				} 
			}
		}
	}
	.hamburger {
		&__inside {
			span {
				text-shadow: none;
				height: 2px;
				&:nth-child(1) {
					transform: translateY(10px) rotate(-225deg);
					@include query($q520) {
						transform: translateY(7px) rotate(-225deg);
					}
				}
				&:nth-child(2) {
					transform: translateY(0) rotate(225deg);
					width: 100%
				}
				&:nth-child(3) {
					opacity: 0;
				}
			}
		}
	}
}//EFFECT